import { SceneUiMutation, SceneUiState } from './types'
import { MutationTree } from 'vuex'
import { SceneModel, DaysEnum, SceneControlCommandModel, SceneTimeScheduleModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { deepCopy, removeWithSelector, upsertWithSelector } from '@ecocoach/domain-store-modules/src/utils'
import { getSchedule, defaultScheduleDateTime, newSchedule, scheduleOfSceneIsValid } from './helpers'
import moment from 'moment'

const newScene = (projectId: string) => ({
  id: '',
  projectId,
  name: '',
  iconResourceId: 'default.white',
  colorGradient: 'gradient-13',
  controls: [],
  controlCommands: [],
  timeSchedules: [newSchedule()],
} as SceneModel)


const copyScene = (scene: SceneModel) => {
  const schedule = getSchedule(scene)
  const copy = deepCopy(scene)
  if (schedule) {
    copy.timeSchedules[0].triggerDateTime = schedule.triggerDateTime
      ? moment.utc(schedule.triggerDateTime).toISOString()
      : defaultScheduleDateTime()
  }

  return copy
}

export const mutations: MutationTree<SceneUiState> = {
  [SceneUiMutation.setScene](state, scene: SceneModel) {
    const copy = copyScene(scene)
    if (getSchedule(scene) && !scheduleOfSceneIsValid(scene, state.dateTimeUtcNow)) {
      copy.timeSchedules[0].isEnabled = false
    }
    state.scene = copy
    state.originalScene = copyScene(copy)
  },
  [SceneUiMutation.resetScene](state, projectId: string) {
    state.scene = newScene(projectId)
    state.originalScene = newScene(projectId)
  },
  [SceneUiMutation.setSceneName](state, name: string) {
    state.scene.name = name
  },
  [SceneUiMutation.setSceneIcon](state, icon: string) {
    state.scene.iconResourceId = icon
  },
  [SceneUiMutation.setSceneGradient](state, gradient: string) {
    state.scene.colorGradient = gradient
  },
  [SceneUiMutation.upsertSceneControlCommands](state, sceneControlCommands: SceneControlCommandModel[]) {
    const selector = (a: SceneControlCommandModel, b: SceneControlCommandModel) => a.controlId === b.controlId && a.command === b.command
    sceneControlCommands.forEach(scc => upsertWithSelector(state.scene.controlCommands, scc, existing => selector(scc, existing)))
  },
  [SceneUiMutation.removeSceneControlCommands](state, controlId: string) {
    state.scene.controlCommands = removeWithSelector(state.scene.controlCommands, c => c.controlId === controlId)
  },
  [SceneUiMutation.setSchedule](state, schedule: SceneTimeScheduleModel) {
    state.scene.timeSchedules = [schedule]
  },
  [SceneUiMutation.setScheduleEnabled](state, isEnabled: boolean) {
    state.scene.timeSchedules[0].isEnabled = isEnabled
  },
  [SceneUiMutation.setScheduleIsRecurring](state, isRecurring: boolean) {
    state.scene.timeSchedules[0].isRecurring = isRecurring
  },
  [SceneUiMutation.setScheduleDateTime](state, dateTime: string) {
    state.scene.timeSchedules[0].triggerDateTime = dateTime
  },
  [SceneUiMutation.setScheduleDays](state, days: DaysEnum[]) {
    state.scene.timeSchedules[0].days = days
  },
  [SceneUiMutation.setSceneActivating](state, sceneId: string) {
    state.activatingSceneIds.push(sceneId)
  },
  [SceneUiMutation.resetSceneActivating](state, sceneId: string) {
    state.activatingSceneIds = state.activatingSceneIds.filter(id => id !== sceneId)
  },
  [SceneUiMutation.setDateTimeUtcNow](state, dateTime: string) {
    state.dateTimeUtcNow = dateTime
  },
}

<template>
  <div 
    class="user-agreement-content"
    v-html="content">
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class UserAgreementContent extends Vue {
  @Prop() public content: string
}
</script>

<style lang="scss" scoped>
.user-agreement-content { 
  text-align: left;
  word-wrap: break-word;
  padding: 15px;
}
</style>

<style lang="css">
.user-agreement-content section.allgemeine ol {
  counter-reset: li;
}
.user-agreement-content ol, ul {
  list-style: none;
  padding-left: 0px;
}
.user-agreement-content p {
  margin-bottom: 0px;
}
.user-agreement-content section.allgemeine .top-list>li:before {
  content: counter(li) ". ";
}
.user-agreement-content section.allgemeine li:before {
  counter-increment: li;
  content: counters(li,".") " ";
}
.user-agreement-content section.allgemeine .top-list>li {
  margin-top: 15px;
}
.user-agreement-content section.allgemeine .top-list>li span.clause-title {
  font-weight: 700;
}
.user-agreement-content section.datenschutz>ol>li {
  font-weight: 700;
  margin-top: 15px;
}
.user-agreement-content section.datenschutz>ol>li>div {
  font-weight: 300;
}
.user-agreement-content td {
  vertical-align: baseline;
}
</style>
import { CustomPlcAlarmModel } from '@ecocoach/domain-store-modules/src/alarm/models'
import moment from 'moment'

export function sortedCustomPlcAlarms(alarms: CustomPlcAlarmModel[]): CustomPlcAlarmModel[] {
  return [...alarms].sort((a, b) => {
    const isOpenA = a.isOpen ? a.openedTimestamp : a.closedTimestamp
    const isOpenB = b.isOpen ? b.openedTimestamp : b.closedTimestamp
    return isOpenA.localeCompare(isOpenB)
  })
}

export function formatAlarmHistoryTimeStamp(dateTimeUtc: string) {
  return moment.utc(dateTimeUtc).local().format('DD.MM.YYYY HH:mm:ss')
}

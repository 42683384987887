import { getters } from './getters'
import { GradientGetter } from './types'
import { RootState } from '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true
export const gradient: Module<{}, RootState> = {
  namespaced,
  getters,
}

export const types = {
  getter: GradientGetter,
}

export const domain = 'gradient'

export const vuexModule = {
  [domain]: gradient,
}

declare global {
  interface Window {
    cordova?: any,
  }
}

import Vue from 'vue'
import Router, { Route, RawLocation } from 'vue-router'
import Tab from '@/views/Tab.vue'
import Update from '@/views/Update.vue'
import Welcome from '@/views/Welcome.vue'
import AcceptUserAgreements from '@/views/AcceptUserAgreements.vue'
import AcceptUserAgreement from '@/views/AcceptUserAgreement.vue'
import Favorites from '@/views/Favorites.vue'
import Device from '@/views/Device.vue'
import Rooms from '@/views/Rooms.vue'
import Room from '@/views/Room.vue'
import EnergyNext from '@/views/EnergyNext.vue'
import Scenes from '@/views/Scenes.vue'
import Scene from '@/views/Scene.vue'
import Schedule from '@/views/Schedule.vue'
import More from '@/views/More.vue'
import Projects from '@/views/Projects.vue'
import Support from '@/views/Support.vue'
import SupportContact from '@/views/SupportContact.vue'
import ShareDebugInfo from '@/views/ShareDebugInfo.vue'
import UserAgreements from '@/views/UserAgreements.vue'
import UserAgreement from '@/views/UserAgreement.vue'
import About from '@/views/About.vue'
import Account from '@/views/Account.vue'
import Language from '@/views/Language.vue'
import Developer from '@/views/Developer.vue'
import BackendEnvironment from '@/views/BackendEnvironment.vue'
import LogLevel from '@/views/LogLevel.vue'
import Loading from '@/views/Loading.vue'
import NotFound from '@/views/NotFound.vue'
import DemoProjects from '@/views/DemoProjects.vue'
import EnvironmentService from '@ecocoach/domain-store-modules/src/services/environment.service'
import AlarmsOverview from '@/views/AlarmsOverview.vue'
import MfaConfiguration from '@/views/MfaConfiguration.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/root',
      component: Loading,
      name: 'root',
    },
    {
      path: '/acceptuseragreements',
      component: Tab,
      children: [
        {
          path: '',
          name: 'acceptuseragreements',
          component: AcceptUserAgreements,
        },
        {
          path: 'acceptuseragreements/:type',
          name: 'acceptuseragreement',
          component: AcceptUserAgreement,
        },
      ],
    },
    {
      path: '/favorites',
      component: Tab,
      children: [
        {
          path: '',
          name: 'favorites',
          component: Favorites,
        },
        {
          path: 'device/:deviceId',
          name: 'favoritedevice',
          component: Device,
        },
      ],
    },
    {
      path: '/rooms',
      component: Tab,
      children: [
        {
          path: '',
          name: 'rooms',
          component: Rooms,
        },
        {
          path: 'room/:roomId',
          name: 'room',
          component: Room,
        },
        {
          path: 'room/:roomId/device/:deviceId',
          name: 'roomdevice',
          component: Device,
        },
      ],
    },
    {
      path: '/energynext',
      component: Tab,
      children: [
        {
          path: '',
          name: 'energynext',
          component: EnergyNext,
        },
        {
          path: 'device/:deviceId',
          name: 'energydevicenext',
          component: Device,
        },
      ],
    },
    {
      path: '/scenes',
      component: Tab,
      children: [
        {
          path: '',
          name: 'scenes',
          component: Scenes,
        },
        {
          path: 'scene/add',
          name: 'addscene',
          component: Scene,
        },
        {
          path: 'scene/:sceneId',
          name: 'scene',
          component: Scene,
        },
        {
          path: 'scene/:sceneId/schedule',
          name: 'schedule',
          component: Schedule,
        },
      ],
    },
    {
      path: '/more',
      component: Tab,
      children: [
        {
          path: '',
          name: 'more',
          component: More,
        },
        {
          path: 'projects',
          name: 'projects',
          component: Projects,
        },
        {
          path: 'support',
          name: 'support',
          component: Support,
        },
        {
          path: 'support/contact',
          name: 'contact',
          component: SupportContact,
        },
        {
          path: 'support/sharedebuginfo',
          name: 'sharedebuginfo',
          component: ShareDebugInfo,
        },
        {
          path: 'about',
          name: 'about',
          component: About,
        },
        {
          path: 'useragreements',
          name: 'useragreements',
          component: UserAgreements,
        },
        {
          path: 'useragreements/:type',
          name: 'useragreement',
          component: UserAgreement,
        },
        {
          path: 'account',
          name: 'account',
          component: Account,
        },
        {
          path: 'account/language',
          name: 'language',
          component: Language,
        },
        {
          path: 'account/mfa',
          name: 'mfa',
          component: MfaConfiguration,
        },
        {
          path: 'demoprojects',
          name: 'demoprojects',
          component: DemoProjects,
        },
        {
          path: 'developer',
          name: 'developer',
          component: Developer,
        },
        {
          path: 'developer/environment',
          name: 'environment',
          component: BackendEnvironment,
        },
        {
          path: 'developer/loglevel',
          name: 'loglevel',
          component: LogLevel,
        },
        {
          path: 'alarmsOverview',
          name: 'alarmsOverview',
          component: AlarmsOverview,
        },
        {
          path: 'alarmdevice/:deviceId',
          name: 'alarmdevice',
          component: Device,
        },
      ],
    },
    {
      path: '/update',
      name: 'update',
      component: Update,
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
    },
    {
      path: '/notfound/:id',
      name: 'notfound',
      component: NotFound,
    },
    {
      path: '/loading',
      name: 'loading',
      component: Loading,
    },
    {
      path: '*',
      component: Loading,
    },
  ],
})

// stack router behavior: keep track of history stack
let navigationHistoryStack: string[] = []
let initialized = false

// restore scrolling of the content element when navigation back
// inspired by: https://github.com/vuejs/vue-router/issues/1187#issuecomment-331410618
const scrollableElementId = 'app-view-content'
const scrollPositions = new Map<string, number>()

function storeScrollPositionForRoute(path: string) {
  const element = document.getElementById(scrollableElementId)
  if (element !== null) {
    const scrollPosition = element.scrollTop
    scrollPositions.set(path, scrollPosition)
    Vue.$log.info(`navigation:scrollPositionStored for ${path}: ${scrollPosition}`)
  }
}

function restoreScrollPositionForRoute(path: string) {
  const scrollPosition = scrollPositions.get(path)
  if (scrollPosition) {
    setTimeout(() => {
      const element = document.getElementById(scrollableElementId)
      if (element) {
        Vue.$log.info(`navigation:scrollPositionsRestoring for ${path}: ${scrollPosition}`)
        element.scrollTop = scrollPosition
      }
    }, 50)
  }
  // cleanup stored scroll positions
  scrollPositions.forEach((_, key) => {
    if (!navigationHistoryStack.includes(key)) {
      scrollPositions.delete(key)
    }
  })
}

function createNavigationInfo(to: Route, from: Route) {
  const fromPath = from.path
  const toPath = to.path
  const fromPathIsRoot = fromPath === '/'
  const toPathIsRoot = toPath === '/'
  const fromPathTokens = fromPath.split('/').filter((_) => _)
  const toPathTokens = toPath.split('/').filter((_) => _)
  const fromTab = fromPathTokens[0]
  const toTab = toPathTokens[0]
  const fromDepth = fromPathTokens.length
  const toDepth = toPathTokens.length

  const isNavigationByRoute = !fromPathIsRoot && !toPathIsRoot
  const isNavigationByBackButton = !fromPathIsRoot && toPathIsRoot
  const isTabChanged = (fromTab !== toTab)
  const isGoingDeeper = toDepth > fromDepth
  const currentlyOnTabRoot = navigationHistoryStack.length === 0

  const isInitializeNavigation = fromPathIsRoot && toPath === '/root'
  const isGoToTabNavigation = isNavigationByRoute && toDepth === 1
  const isGoDeeperNavigation = isNavigationByRoute && isGoingDeeper && !isTabChanged
  const isGoShallowerNavigation = isNavigationByBackButton && !currentlyOnTabRoot
  const isLeaveAppNavigation =  isNavigationByBackButton && currentlyOnTabRoot

  return {
    fromPath,
    toPath,
    isInitializeNavigation,
    isGoDeeperNavigation,
    isGoShallowerNavigation,
    isGoToTabNavigation,
    isLeaveAppNavigation,
  }
}

router.beforeEach((to, from, next) => {
  Vue.$log.debug('navigation:router.beforeEach')
  Vue.$log.debug('navigation:history', window.history)

  const navigationInfo = createNavigationInfo(to, from)
  Vue.$log.debug('navigation:navigationInfo', navigationInfo)
  Vue.$log.debug('navigation:navigationStack', navigationHistoryStack)
  if (!initialized) {
    Vue.$log.debug(`navigation:uninitialized navigation from ${navigationInfo.fromPath} to ${navigationInfo.toPath}, aborting`)
    next(false)
    return
  }
  if (navigationInfo.fromPath === navigationInfo.toPath) {
    Vue.$log.debug(`navigation:self navigation from ${navigationInfo.fromPath} to ${navigationInfo.toPath}, aborting`)
    next(false)
    return
  }
  if (navigationInfo.isInitializeNavigation) {
    Vue.$log.info(`navigation:isInitializeNavigation from ${navigationInfo.fromPath} to ${navigationInfo.toPath}, navigating`)
    next()
    return
  }
  if (navigationInfo.isGoToTabNavigation) {
    Vue.$log.info(`navigation:isGoToTabNavigation from ${navigationInfo.fromPath} to ${navigationInfo.toPath}, clearing history stack`)
    navigationHistoryStack = []
    next()
    return
  }
  if (navigationInfo.isGoDeeperNavigation) {
    Vue.$log.info(`navigation:isGoDeeperNavigation from ${navigationInfo.fromPath} to ${navigationInfo.toPath}, pushing to history stack`)
    if (!navigationHistoryStack.includes(from.path)) {
      navigationHistoryStack.push(from.path)
    }
    storeScrollPositionForRoute(navigationInfo.fromPath)
    next()
    return
  }
  if (navigationInfo.isGoShallowerNavigation) {
    const backNavigationPath = navigationHistoryStack.pop()
    Vue.$log.info(`navigation:isGoShallowerNavigation from ${navigationInfo.fromPath} to ${backNavigationPath}, replacing route`)
    next({path: backNavigationPath})
    return
  }
  if (navigationInfo.isLeaveAppNavigation) {
    if (EnvironmentService.isRunningOnIos) {
      Vue.$log.debug(`navigation:isLeaveAppNavigation on iOS from ${navigationInfo.fromPath} to ${navigationInfo.toPath}, aborting`)
      next(false)
      return
    } else if (EnvironmentService.isRunningOnAndroid) {
      Vue.$log.debug(`navigation:isLeaveAppNavigation on iOS from ${navigationInfo.fromPath} to ${navigationInfo.toPath}, exiting`)
      next(false)
      window.cordova.plugins.exit()
      return
    } else {
      Vue.$log.debug(`navigation:isLeaveAppNavigation on web from ${navigationInfo.fromPath} to ${navigationInfo.toPath}, proceeding`)
      router.back()
      next()
    }
  }
  Vue.$log.debug(`navigation:fallthrough ${navigationInfo.fromPath} to ${navigationInfo.toPath}, navigating`)
  next()
})

router.afterEach((to, from) => {
  Vue.$log.debug('navigation:router.afterEach')
  const navigationInfo = createNavigationInfo(to, from)
  if (navigationInfo.isLeaveAppNavigation) {
    Vue.$log.info('navigation:isLeaveAppNavigation, goBack')
    router.back()
  } else {
    restoreScrollPositionForRoute(navigationInfo.toPath)
  }
  Vue.$log.debug('navigation:navigationStack after', navigationHistoryStack)
})

export async function init() {
  if (!initialized) {
    initialized = true
    await router.push({ name: 'root' })
  }
}

export async function navigate(location: RawLocation, replaceHistoryStack?: string[]) {
  if (replaceHistoryStack) {
    navigationHistoryStack = replaceHistoryStack
  }
  await router.replace(location)
}

export function canGoBack() {
  return !!navigationHistoryStack.length
}

export function goBack() {
  if (canGoBack()) {
    Vue.$log.debug('navigation:goBack')
    router.back()
  }
}

const StackRouter = {
  router,
  init,
  navigate,
  canGoBack,
  goBack,
}
export default StackRouter

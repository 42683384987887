import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'
import { SceneUiAction, SceneUiGetter, SceneUiMutation, SceneUiState } from './types'
import { RootState } from '@/store/types'

const namespaced: boolean = true

export const sceneUi: Module<SceneUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action:  SceneUiAction,
  mutation: SceneUiMutation,
  getter: SceneUiGetter,
}

export const domain = 'sceneUi'

export const vuexModule = {
  [domain]: sceneUi,
}

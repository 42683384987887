<template>
  <v-layout row align-center justify-space-between class="app-header">
    <v-flex v-if="canGoBack" shrink class="app-header-back">
      <icon-button class="app-header-back-button"
        @buttonClicked="onBackButtonClicked"
        type="materialIcon"
        materialIconName="arrow_back"
        :height="25"
        :width="25"
        :enabled="canGoBack"
      />
    </v-flex>
    <v-flex v-if="!canGoBack" shrink class="app-header-logo">
      <svg-icon class="logo-image" :icon="'eco.logo.grey'" :width="40" :height="40"/>
    </v-flex>
    <v-flex v-if="!canGoBack" shrink class="app-header-separator"/>
    <v-flex v-if="showHeaderInfo" shrink class="app-header-text">{{ headerText }}</v-flex>
    <v-flex grow class="app-header-menu">
      <v-layout v-if="showHeaderInfo" row align-center justify-end>
        <slot name="actions"/>
        <icon-button-with-badge v-if="alarms.length > 0"
          material-icon="alarm"
          :badge="alarms.length"
          @click="setDrawerVisible(true)"
        />
        <icon-button-with-badge v-offline class="offline-icon"
          material-icon="signal_cellular_off"
          badge="!"
          @click="setDrawerVisible(true)"
        />
      </v-layout>
    </v-flex>
    <alarms v-if="isDrawerVisible"/>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StackRouter from '../router'
import Alarms from './Alarms.vue'
import IconButtonWithBadge from './shared/IconButtonWithBadge.vue'
import SvgIcon from './shared/SvgIcon.vue'
import { Route } from 'vue-router'
import { PlcModel, ProjectModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'

const App = namespace('app')
const AlarmUi = namespace('alarmUi')

@Component({
  components: {
    Alarms,
    IconButtonWithBadge,
    SvgIcon,
  },
})
export default class AppHeader extends Vue {
  @App.Getter public selectedProject: ProjectModel | null
  @App.Getter public selectedPlc: PlcModel | null
  @App.Getter public showNavigationForRoute: (route: Route) => boolean
  @App.Getter public showPlcNameInHeaderForRoute: (route: Route) => boolean
  @AlarmUi.Getter public alarms
  @AlarmUi.Getter public isDrawerVisible: boolean
  @AlarmUi.Mutation public setDrawerVisible: (visible: boolean) => void

  public get showHeaderInfo() {
    return this.showNavigationForRoute(this.$route)
  }

  public get headerText() {
    if (this.showPlcNameInHeaderForRoute(this.$route)) {
      return this.selectedPlc?.name ?? ''
    }
    return this.selectedProject?.name ?? ''
  }

  public get canGoBack() {
    return StackRouter.canGoBack() && this.showHeaderInfo
  }

  public onBackButtonClicked() {
    Vue.$log.debug('onBackButtonClicked')
    StackRouter.goBack()
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  background-color: #09162a;
  .app-header-back {
    width: 51px;
  }
  .app-header-back-button {
    margin: 0px;
    height: 35px;
    width: 35px
  }
  .app-header-separator {
    height: 15px;
    min-width: 1px;
    background-color: #7a8b9a;
    margin: 0px 5px;
  }
  .app-header-text {
    font-size: 16px;
    color: #7a8b9a;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .app-header-menu {
    margin-right: 15px;
    .icon-button {
      margin: 0px;
    }
    .offline-icon {
      margin-left: 10px;
    }
  }
}

</style>

<style lang="css">
</style>
import { SceneModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'

export interface SceneUiState {
  scene: SceneModel
  originalScene: SceneModel
  activatingSceneIds: string[]
  dateTimeUtcNow: string
}

export enum SceneUiAction {
  loadScene = 'loadScene',
  createScene = 'createScene',
  saveScene = 'saveScene',
  deleteScene = 'deleteScene',
  addDeviceToScene = 'addDeviceToScene',
  removeDevicefromScene = 'removeDevicefromScene',
  updateSceneControlCommand = 'updateSceneControlCommand',
  activateScene = 'activateScene',
  loadSchedule = 'loadSchedule',
  createSchedule = 'createSchedule',
  saveSchedule = 'saveSchedule',
  deleteSchedule = 'deleteSchedule',
  setScheduleType = 'setScheduleType',
  setScheduleDate = 'setScheduleDate',
  setScheduleTime = 'setScheduleTime',
  activateSchedule = 'activateSchedule',
  deactivateSchedule = 'deactivateSchedule',
  startTimer = 'startTimer',
  stopTimer = 'stopTimer',
}

export enum SceneUiMutation {
  setScene = 'setScene',
  resetScene = 'resetScene',
  setSceneName = 'setSceneName',
  setSceneIcon = 'setSceneIcon',
  setSceneGradient = 'setSceneGradient',
  upsertSceneControlCommands = 'upsertSceneControlCommands',
  removeSceneControlCommands = 'removeSceneControlCommands',
  setSchedule = 'setSchedule',
  setScheduleEnabled = 'setScheduleEnabled',
  setScheduleIsRecurring = 'setScheduleIsRecurring',
  setScheduleDateTime = 'setScheduleDateTime',
  setScheduleDays = 'setScheduleDays',
  setSceneActivating = 'setSceneActivating',
  resetSceneActivating = 'resetSceneActivating',
  setDateTimeUtcNow = 'setDateTimeUtcNow',
}

export enum SceneUiGetter {
  scene = 'scene',
  scenes = 'scenes',
  plcs = 'plcs',
  roomsOfPlc = 'roomsOfPlc',
  devicesOfRoom = 'devicesOfRoom',
  checkedDevicesOfRoom = 'checkedDevicesOfRoom',
  controlsOfDevice = 'controlsOfDevice',
  sceneSavable = 'sceneSavable',
  scheduleTypeOptions = 'scheduleTypeOptions',
  scheduleDaysOptions = 'scheduleDaysOptions',
  dateTimeUtcNow = 'dateTimeUtcNow',
}

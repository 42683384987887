<template>
  <app-view>
    <icon-header
      :title="'navigation.useragreements' | translate"
      material-icon="menu_book"
    />
    <list-navigation-item v-for="userAgreement in userAgreements" :key="userAgreement.type"
      :material-icon="userAgreement.accepted ? 'check_box' : 'check_box_outline_blank'"
      :label="userAgreement.name"
      :value="userAgreement.accepted ? '': 'common.button.accept'"
      @selected="navigateToUserAgreement(userAgreement.type)"
    />
    <div>
      <base-text-button @buttonClicked="navigateNext" :enabled="canContinue">{{ 'common.button.next' | translate }}</base-text-button>
    </div>
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'
import { UserAgreementViewModel } from '../store/modules/app/models'
import StackRouter from '../router'
import { PlcModel, ProjectModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'

const App = namespace('app')
const Resource = namespace('resource')
const DeviceUi = namespace('deviceUi')

@Component({
  components: {
    AppView,
    IconHeader,
    ListNavigationItem,
  },
})
export default class AcceptUserAgreements extends Vue {
  @App.Getter public userAgreements: UserAgreementViewModel[]
  @App.Getter public projects: ProjectModel[]
  @App.Getter public includeDemoProjects: boolean | null
  @App.Getter public hasDemoProjects: boolean
  @App.Action public toastInfo: (message: string) => Promise<void>
  @App.Action public hideToast: () => Promise<void>
  @Resource.Getter public dictionary
  @DeviceUi.Getter public favoritePlcs: PlcModel[]

  public mounted() {
    if (!this.canContinue) {
      this.toastInfo(this.dictionary('useragreements.accept.message'))
    }
  }

  public get canContinue() {
    return !this.userAgreements.some((_) => !_.accepted)
  }

  public get shouldShowWelcomePage(): boolean {
    return !this.projects.length && this.hasDemoProjects && this.includeDemoProjects === null
  }

  public get hasFavorites(): boolean {
    return !!this.favoritePlcs.length
  }

  public navigateNext() {
    if (this.shouldShowWelcomePage) {
      StackRouter.navigate({ name: 'welcome' })
    } else if (this.hasFavorites) {
      StackRouter.navigate({ name: 'favorites' })
    } else {
      StackRouter.navigate({ name: 'rooms' })
    }
  }

  public navigateToUserAgreement(type: string) {
    this.hideToast()
    Vue.$log.info('Navigating to user agreement', type)
    StackRouter.navigate({ name: 'acceptuseragreement', params: { type } })
  }
}
</script>

<style lang="scss" scoped>
.v-btn {
  border: 1px solid white;
}
</style>
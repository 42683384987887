import { ActionTree } from 'vuex'
import { AlarmLogUiAction, AlarmLogUiMutation, AlarmLogUiState } from '@/store/modules/alarmLogUi/types'
import { RootState } from '@/store/types'
import { AlarmAction } from '@ecocoach/domain-store-modules/src/alarm/types'
import { LoadCustomPlcAlarmsPayload } from '@ecocoach/domain-store-modules/src/alarm/models'
import { sortedCustomPlcAlarms } from '@/store/modules/alarmLogUi/helpers'

export const actions: ActionTree<AlarmLogUiState, RootState> = {
  async [AlarmLogUiAction.openAlarmLogForProject]({ commit, dispatch, rootState }): Promise<void> {
    try {
      commit(AlarmLogUiMutation.setInteracted, true)
      const projectId = rootState.app.selectedProjectId
      await dispatch(`alarm/${AlarmAction.loadCustomPlcAlarmsForProject}`, {
        projectId,
      } as LoadCustomPlcAlarmsPayload, { root: true })
    } finally {
      commit(AlarmLogUiMutation.setInteracted, false)
    }
  },
  async [AlarmLogUiAction.loadMoreAlarmLogForProject]({ commit, dispatch, rootState }): Promise<void> {
    try {
      commit(AlarmLogUiMutation.setInteracted, true)
      const projectId = rootState.app.selectedProjectId
      const customPlcAlarmsSorted = sortedCustomPlcAlarms(rootState.alarm.customPlcAlarms)
      const oldestCreated = customPlcAlarmsSorted[0]
      const created = oldestCreated.isOpen ? oldestCreated.openedTimestamp : oldestCreated.closedTimestamp
      await dispatch(`alarm/${AlarmAction.loadCustomPlcAlarmsForProject}`, {
        projectId,
        created,
      } as LoadCustomPlcAlarmsPayload, { root: true })
    } finally {
      commit(AlarmLogUiMutation.setInteracted, false)
    }
  },
}

<template>
  <app-view>
    <icon-header
      :title="'settings.support.sharedebuginfo' | translate"
      material-icon="bug_report"
    />
    <list-navigation-item
      :label="'common.button.yes' | translate"
      :active="shareDebugInfo"
      @selected="onShareDebugInfo(true)"
    />
    <list-navigation-item
      :label="'common.button.no' | translate"
      :active="!shareDebugInfo"
      @selected="onShareDebugInfo(false)"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'
import { isLogRocketActive, activateLogRocket } from './../logrocket'

@Component({
  components: {
    AppView,
    IconHeader,
    ListNavigationItem,
  },
})
export default class ShareDebugInfo extends Vue {
  public shareDebugInfo = false

  public async mounted() {
    this.shareDebugInfo = await isLogRocketActive()
  }

  public async onShareDebugInfo(value: boolean) {
    Vue.$log.info('shareDebugInfo', value)
    if (value !== this.shareDebugInfo) {
      await activateLogRocket(value)
      window.location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
<template>
  <app-view :loading="loading">
    <template v-slot:actions>
      <icon-button
        @buttonClicked="onToggleFavorite"
        type="materialIcon"
        :materialIconName="isFavorite ? 'favorite' : 'favorite_border'"
        :height="20"
        :width="20"
        :enabled="!isInteracted"
      />
    </template>
    <div class="device">
      <svg-icon :icon="device && device.icon" :width="70" :height="70"/>
      <div class="device-name">{{ device && device.name }}</div>
      <div class="device-controls">
        <device-control v-for="control in controlsOfDevice"
          :key="control.id"
          :control="control"
        />
      </div>
    </div>
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import StackRouter from '../router'
import DeviceControl from './shared/DeviceControl.vue'
import SvgIcon from './shared/SvgIcon.vue'
import { ControlModelV2, DeviceModel } from '../../../eco-domain-store-modules/src/plcOperation/models'

const App = namespace('app')
const DeviceUi = namespace('deviceUi')

@Component({
  components: {
    AppView,
    DeviceControl,
    SvgIcon,
  },
})
export default class Device extends Vue {
  @App.Getter public isInteracted: boolean
  @DeviceUi.Getter public loading: boolean
  @DeviceUi.Action public createFavorite: (id: string) => Promise<void>
  @DeviceUi.Action public deleteFavorite: (id: string) => Promise<void>
  @DeviceUi.Getter public deviceById: (deviceId: string) => DeviceModel
  @DeviceUi.Getter public deviceControlsOfDevice: (deviceId: string) => ControlModelV2[]
  @DeviceUi.Getter public isFavoriteDevice: (deviceId: string) => boolean
  @DeviceUi.Action public startControlUpdatesForDevice: (deviceId: string) => Promise<void>

  public async mounted() {
    if (!this.device) {
      Vue.$log.warn('device not found', this.deviceId)
      await StackRouter.navigate({ name: 'notfound', params: { id: this.deviceId } })
      return
    }
    this.startControlUpdatesForDevice(this.deviceId)
  }

  public get deviceId(): string {
    return this.$route.params.deviceId
  }

  public get device(): DeviceModel {
    return this.deviceById(this.deviceId)
  }

  public get controlsOfDevice(): ControlModelV2[] {
    return this.deviceControlsOfDevice(this.deviceId)
  }

  public get isFavorite() {
    return this.isFavoriteDevice(this.deviceId)
  }

  public onToggleFavorite() {
    if (!this.isFavorite) {
      this.createFavorite(this.deviceId)
    } else {
      this.deleteFavorite(this.deviceId)
    }
  }
}
</script>
<style lang='scss' scoped>
.device {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  .device-name {
    margin: 15px 0px;
    font-size: 16px;
    max-width: 100%;
  }
  .device-controls {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
  }
}
</style>
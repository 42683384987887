<template>
  <v-app id="app" dark v-swipe-right="onSwipeRight">
    <router-view/>
    <div id="app-footer" v-if="showNavigation" :class="{ 'extend-bottom-bar': hasIosSafeArea}">
      <navigation-bar class="navigation-bar"
        :links="tabs"
        :active-link="activeTabId"        
        @goToLink="onTabClicked"
      />
    </div>
    <v-snackbar
      class="toast"
      :value="toast"
      :color="toast && toast.color"
      :timeout="toast && toast.timeout"
      :vertical="true"
    >
      <div class="toast-text" v-html="toast && toast.message || ''"></div>
      <v-btn dark flat @click="setToast(null)" class="toast-button">
        {{ 'common.button.close' | translate}}
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Route } from 'vue-router'
import { IncludeDemoProjectsStorageKey, LanguageStorageKey, TabModel, ToastModel, UserAgreementViewModel } from './store/modules/app/models'
import StackRouter from './router'
import AppHeader from './views/AppHeader.vue'
import { Services } from '../../eco-domain-store-modules/src'
import EnvironmentService from '../../eco-domain-store-modules/src/services/environment.service'
import AppDataStorageService from '../../eco-domain-store-modules/src/services/appdatastorage.service'
import { PlcModel, ProjectModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'

const App = namespace('app')
const DeviceUi = namespace('deviceUi')
const Authentication = namespace('authentication')
const PlcOperation = namespace('plcOperation')
const Resource = namespace('resource')

@Component({
  components: {
    AppHeader,
  },
})
export default class AppComponent extends Vue {
  @App.Getter public showNavigationForRoute: (route: Route) => boolean
  @App.Getter public updateRequired: boolean
  @App.Getter public tabs: TabModel[]
  @App.Getter public projects: ProjectModel[]
  @App.Getter public selectedProjectId: string
  @App.Getter public userAgreements: UserAgreementViewModel[]
  @App.Getter public languages: Array < { id: string, label: string } >
  @App.Getter public includeDemoProjects: boolean | null
  @App.Getter public hasDemoProjects: boolean
  @App.Action public load: () => Promise<void>
  @App.Action public selectLanguage: (language: string) => Promise<void>
  @App.Mutation public setIncludeDemoProjects: (includeDemoProjects: boolean | null) => void
  @App.Getter public toast: ToastModel | null
  @App.Mutation public setToast: (toast: ToastModel | null) => void
  @App.Action public toastWarning: (message: string) => Promise<void>
  @DeviceUi.Getter public favoritePlcs: PlcModel[]
  @PlcOperation.Getter public hasControlUpdateFailed: boolean
  @PlcOperation.Getter public hasControlUpdateTimeout: boolean
  @Authentication.Getter public loggedIn: boolean
  @Resource.Getter public dictionary

  public async mounted() {
    Vue.$log.debug('app mounted')
    await this.initialize()
  }

  public get showNavigation() {
    return this.showNavigationForRoute(this.$route)
  }

  public onSwipeRight(e: any) {
    // swipe right most of the screen to go back (60px is chosen to exceed the sliders)
    const swipeTolerance = (this as any).$vssWidth - 60
    // detect end of swipe outside screen
    const endedInsideWindow = e.center.x < (this as any).$vssWidth
    Vue.$log.info('onSwipeRight', e.deltaX, swipeTolerance, endedInsideWindow)
    if (e.deltaX > swipeTolerance && endedInsideWindow) {
      StackRouter.goBack()
    }
  }

  public get activeTabId() {
    Vue.$log.debug('activeTabId evaluated')
    const activeTab = this.tabs.length > 0
      && (this.$route.path.split('/').filter((_) => _)[0] || this.tabs[0].id)
    Vue.$log.debug('activeTab', activeTab)
    return activeTab || ''
  }

  @Watch('hasControlUpdateFailed')
  public showFailedError(failedState: boolean) {
    if (failedState) {
      this.toastWarning(this.dictionary('control.update.failed'))
    }
  }

  @Watch('hasControlUpdateTimeout')
  public showTimeoutError(timeoutOcurred: boolean) {
    if (timeoutOcurred) {
      this.toastWarning(this.dictionary('control.update.failed'))
    }
  }

  public onTabClicked(id: string) {
    Vue.$log.debug('onTabClicked', id)
    Vue.$log.debug('current $route.name', this.$route.name)
    if (this.$route.name !== id) {
      StackRouter.navigate({name: id})
    }
  }

  private async initialize() {
    Vue.$log.debug('configuring language')
    const storedLanguage = await AppDataStorageService.get(LanguageStorageKey)
    await this.selectLanguage(Services.EnvironmentService.determineLanguage(this.languages.map(lang => lang.id), storedLanguage))

    Vue.$log.debug('configuring settings')
    const includeDemoProjects = await AppDataStorageService.getBoolean(IncludeDemoProjectsStorageKey)
    this.setIncludeDemoProjects(includeDemoProjects)

    Vue.$log.debug('initializing stack router')
    await StackRouter.init()

    Vue.$log.debug('loading app')
    await StackRouter.navigate({ name: 'loading' })
    await this.load()

    if (this.updateRequired) {
      Vue.$log.debug('navigating to update')
      await StackRouter.navigate({ name: 'update' })
    } else if (this.hasUnacceptedUserAgreements) {
      Vue.$log.debug('navigating to accept user agreements')
      await StackRouter.navigate({ name: 'acceptuseragreements' })
    } else if (this.shouldShowWelcomePage) {
      Vue.$log.debug('navigating to welcome page')
      await StackRouter.navigate({ name: 'welcome' })
    } else if (this.hasFavorites) {
      Vue.$log.debug('navigating to favorites')
      await StackRouter.navigate({ name: 'favorites' })
    } else {
      Vue.$log.debug('navigating to rooms')
      await StackRouter.navigate({ name: 'rooms' })
    }
  }

  private get hasUnacceptedUserAgreements(): boolean {
    return this.userAgreements.some((_) => !_.accepted)
  }

  private get shouldShowWelcomePage(): boolean {
    return !this.projects.length && this.hasDemoProjects && this.includeDemoProjects === null
  }

  private get hasFavorites(): boolean {
    return !!this.favoritePlcs.length
  }

  private get hasIosSafeArea() {
    return EnvironmentService.hasIosSafeArea
  }
}
</script>

<style lang="scss" scoped>
#app {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: radial-gradient(#1a3856, #080e19);
}
#app-footer {
  font-size: 10px;
  .navigation-bar {
    border-top: 1px solid #fe0036;
  }
}
.toast {
  .toast-text {
    max-height: 30px;
  }
}
</style>
<style lang="css">
* {
  user-select: none
}
input[type="text"], input[type="number"] {
  user-select: text;
}
html, body, #app, .application--wrap {
  height: 100vh !important;
  min-height: 100vh !important;
  font-family: 'Titillium Web', sans-serif;
}
.v-snack__content {
  padding: 10px !important;
}
#app-footer .navigationbar button.link {
  background: linear-gradient(180deg, #12273f, #09162a);
}
#app-footer .navigationbar button:not(:last-child) {
  border-right: 1px solid #0f1820;
}
#app-footer .navigationbar button.link.active {
  background: linear-gradient(180deg, #fe0036, #77032c);
}
#app-footer .navigationbar button.link .link__content span {
  font-weight: inherit;
  font-size: 12px;
  text-transform: inherit;
}
/* iPhone11 unsave bottom area/home bar quirks */
/* add 20px padding at bottom */
#app-footer.extend-bottom-bar .navigationbar {
  height: 75px;
}
#app-footer.extend-bottom-bar .navigationbar button.link {
  padding-bottom: 20px;
}
</style>


function setElementVisibililty(el, show) {
  el.style.display =  show ? '' : 'none'
}

export const offlineDirective = {
  bind: (el) => {
    if (!window.cordova) { // using html5 navigator.onLine
      setElementVisibililty(el, !window.navigator.onLine)
      window.addEventListener('offline',  () => setElementVisibililty(el, true))
      window.addEventListener('online',  () => setElementVisibililty(el, false))
    } else { // using cordova-plugin-network-information
      setElementVisibililty(el, (window.navigator as any).connection.type.toLowerCase() === 'none')
      document.addEventListener('offline', () => setElementVisibililty(el, true))
      document.addEventListener('online', () => setElementVisibililty(el, false))
    }
  },
}

import { AppMutation, AppState } from './types'
import { MutationTree } from 'vuex'
import { ToastModel, AppVersionInfo } from './models'

export const mutations: MutationTree<AppState> = {
  [AppMutation.setInteracted](state: AppState, interacted: boolean) {
    state.interacted = interacted
  },
  [AppMutation.setReady](state: AppState, ready: boolean) {
    state.ready = ready
  },
  [AppMutation.selectProject](state: AppState, projectId: string) {
    state.selectedProjectId = projectId
  },
  [AppMutation.setSelectedPlcId](state: AppState, plcId: string) {
    state.selectedPlcId = plcId
  },
  [AppMutation.setLoading](state: AppState, isLoading: boolean) {
    state.isLoading = isLoading
  },
  [AppMutation.selectLanguage](state: AppState, language: string) {
    state.selectedLanguage = language
  },
  [AppMutation.setIncludeDemoProjects](state: AppState, includeDemoProjects: boolean | null) {
    state.includeDemoProjects = includeDemoProjects
  },
  [AppMutation.setToast](state: AppState, payload: ToastModel | null) {
    state.toast = payload
  },
  [AppMutation.setVersionInfo](state: AppState, payload: AppVersionInfo) {
    state.versionInfo = payload
  },
}

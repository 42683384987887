<template>
  <v-flex 
    :class="[
      'device-box',
      'xs12',
      isIpadMini ? 'md12' : 'md6',
      'lg4',
    ]"
  >
    <slot/>
  </v-flex>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import EnvironmentService from '../../../../eco-domain-store-modules/src/services/environment.service'

@Component({
  components: {
  },
})
export default class DeviceBox extends Vue {
  public get isIpadMini() {
    return EnvironmentService.isIpadMini
  }
}
</script>
<style lang='scss' scoped>
.device-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  border-bottom: 1px solid rgb(45, 52, 66);
}
</style>

import { SceneUiState } from './types'
import { SceneModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'

export const state: SceneUiState = {
  scene: {
    id: '',
    name: '',
    iconResourceId: '',
    colorGradient: '',
    controls: [],
    controlCommands: [],
    timeSchedules: [],
    projectId: '',
  } as SceneModel,
  originalScene: {
    id: '',
    name: '',
    iconResourceId: '',
    colorGradient: '',
    controls: [],
    controlCommands: [],
    timeSchedules: [],
    projectId: '',
  } as SceneModel,
  activatingSceneIds: [],
  dateTimeUtcNow: '',
}

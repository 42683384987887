import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'
import { DeviceUiAction, DeviceUiGetter, DeviceUiMutation, DeviceUiState } from './types'
import { RootState } from '@/store/types'

const namespaced: boolean = true

export const deviceUi: Module<DeviceUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action:  DeviceUiAction,
  mutation: DeviceUiMutation,
  getter: DeviceUiGetter,
}

export const domain = 'deviceUi'

export const vuexModule = {
  [domain]: deviceUi,
}

<template>
  <app-view>
    <v-layout column align-center class="container">
      <svg-icon class="banner" :icon="'eco.banner.switzerland.white'" :width="250" :height="70"/>
      <div class="title-area">
        <p>ecocoach Smart Energy</p>
        <p>{{ 'information.version' | translate }}: {{ version }}</p>
        <p v-if="showBuildNumber">Build: {{ buildNumber }}</p>
      </div>
      <div class="info-area" v-if="updateAvailable">
        <p>{{ 'app.version.updateavailable' | translate }}</p>
        <base-text-button @buttonClicked="onUpdate">{{ 'common.button.update' | translate }}</base-text-button>
      </div>
      <div class="info-area">
        <p>&copy; {{ 'information.copyright' | translate }}</p>
        <p>{{ 'information.right' | translate }}</p>
      </div>
      <a href="https://ecocoach.com">www.ecocoach.com</a>
    </v-layout>
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import SvgIcon from './shared/SvgIcon.vue'
import { openInStore } from '../environment'

const App = namespace('app')

@Component({
  components: {
    AppView,
    SvgIcon,
  },
})
export default class About extends Vue {
  @App.Getter public version: string
  @App.Getter public buildNumber: string
  @App.Getter public updateAvailable: boolean
  @App.Getter public isEcocoachEmployee: boolean

  public get showBuildNumber(): boolean {
    return this.isEcocoachEmployee && !!this.buildNumber
  }

  public onUpdate() {
    openInStore()
  }
}
</script>
<style lang="scss" scoped>
.container {
  margin-top: 40px;
  font-size: 16px;
  .title-area {
    margin-bottom: 20px;
  }
  .banner {
    margin-bottom: 20px;
  }
  .info-area {
    margin-bottom: 20px;
  }
  a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
  .v-btn {
    border: 1px solid white;
  }
}
</style>
<template>
  <v-expansion-panel v-model="isOpen" :expand="true" :readonly="!expandable" class="expandable-panel">
    <v-expansion-panel-content :hide-actions="!expandable">
        <v-layout slot="header" row align-center justify-space-between class="expandable-panel-header">
          <v-flex v-if="showCheckBox" shrink class="header-icon checkbox">
            <checkbox-with-label
              :value="checked"
              @checked="onChecked"
            />
          </v-flex>
          <v-flex v-if="showCheckBox" shrink class="header-spacer"/>
          <v-flex v-if="icon" shrink class="header-icon">
            <icon-with-badge
              :icon="icon"
              :material-icon="materialIcon"
              :badge="badge"
            />
          </v-flex>
          <v-flex shrink class="header-spacer"/>
          <v-flex shrink class="header-title">
              {{title}}
          </v-flex>
          <v-flex grow class="header-spacer"/>
        </v-layout>
      <slot v-if="isOpen[0]"></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import IconWithBadge from './IconWithBadge.vue'

@Component({
  components: {
    IconWithBadge,
  },
})
export default class ExpandablePanel extends Vue {
  @Prop({ default: '' }) public icon: string
  @Prop({ default: '' }) public materialIcon: string
  @Prop({ default: '' }) public title: string
  @Prop({ default: false }) public checkbox: boolean
  @Prop({ default: false }) public checked: boolean
  @Prop({ default: '' }) public badge: string
  @Prop({ default: true }) public expandable: boolean
  @Prop({ default: false }) public open: boolean

  public isOpen = [false]

  public mounted() {
    this.isOpen = [this.open]  
  }
  
  @Watch('open')
  public openChanged(value: boolean) {
    this.isOpen = [value]
  }

  @Emit('onChecked')
  public onChecked(value: boolean) {
    return value
  }

  public get showCheckBox() {
    return !!this.checkbox
  }
}
</script>
<style lang='scss' scoped>
.expandable-panel-header {
  padding: 5px 15px;
  .header-icon {
    height: 20px;
    width: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .v-badge {
      margin-top: 5px;
    }
  }
  .checkbox {
    margin-right: 10px;
  }
  .header-title {
    text-align: left;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;      
  }
  .header-spacer {
    min-width: 10px;
  }
}
</style>
<style lang="css">
.expandable-panel .v-expansion-panel__container {
  background-color: transparent !important;
}
.expandable-panel .v-expansion-panel__header {
  padding: 0 !important;
}
.expandable-panel .v-badge__badge {
  font-size: 10px;
  height: 15px;
  width: 15px;
}
</style>

import Hammer from 'hammerjs'

export const swipeRightDirective = {
  bind: (element, binding) => {
    const isDeviceMode = 'TouchEvent' in window && 'ontouchstart' in window
    if (typeof binding.value === 'function') {
      if (!(element as any).hammer) {
        (element as any).hammer = new Hammer.Manager(element, {
          touchAction: 'auto',
          inputClass: isDeviceMode ? Hammer.TouchInput : Hammer.PointerInput,
          recognizers: [
            [Hammer.Swipe, {
              direction: Hammer.DIRECTION_RIGHT,
            }],
          ],
        })
      }
      (element as any).hammer.on('swipe', binding.value)
    }
  },
}

<template>
  <div class="icon-with-gradient" :style="iconStyle">
    <svg-icon :icon="icon" :width="20" :height="20" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import SvgIcon from './SvgIcon.vue'
import { Gradients } from '@ecocoach/shared-components/src'

@Component({
  components: {
    SvgIcon,
  },
})
export default class IconWithGradient extends Vue {
  @Prop() public icon: string
  @Prop() public gradient: string

  public get iconStyle() {
    return {
      background: `linear-gradient(-135deg, ${Gradients.primaryColor(this.gradient)} 0%, ${Gradients.secondaryColor(this.gradient)} 100%`,
    }
  }
}
</script>
<style lang='scss' scoped>
.icon-with-gradient {
  height: 35px;
  width: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
    

export enum PeriodType {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export enum IntervalType {
  FifteenMinutes = 'FifteenMinutes',
  Daily = 'Daily',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export enum ChartType {
  Line = 'Line',
  Area = 'Area',
  Column = 'Column',
}

export enum StackingType {
  None = 'None',
  Normal = 'Normal',
}

export interface ChartCollectionModel {
  id: string
  name: string
  icon: string
  charts: ChartModel[]
}

export interface ChartModel {
  id: string
  name: string
  description: string
  periods: PeriodType[]
  energyManagementTopologyNodes: EnergyManagementTopologyNodeModel[]
  scalableToAuthorized: boolean
}

export interface EnergyManagementTopologyNodeModel {
  id: string
  name: string
  isRoot: boolean
}

export interface ChartDataModel {
  id: string
  dateFrom: string
  dateTo: string
  period: PeriodType
  interval: IntervalType
  type: ChartType
  stacking: string
  series: ChartDataSeriesModel[]
  keyFigures: ChartDataKeyFiguresModel[]
}

export interface ChartDataSeriesModel {
  id: string
  name: string
  tag: string
  group: string
  data: Array<number | null>
  unit: string
  legend: {
    name: string
    value: number | null
    unit: string
  }
  style: {
    colorGradient: string
  }
}

export interface ChartDataKeyFiguresModel {
  id: string
  name: string
  tag: string
  legend: {
    name: string
    value: number | null
    unit: string
  }
  style: {
    colorGradient: string
  }
}

export interface ChartDataInput {
  projectId: string
  chartId: string
  period: string
  dateFrom: string
  dateTo: string
}

export interface ChartReportInput {
  projectId: string
  chartId: string
  dateFrom: string
  dateTo: string
  fileName: string
}

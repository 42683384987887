<template>
  <v-layout row align-center justify-space-between v-ripple
    class="list-navigation-item"
    :class="{ selected: active }"
    @click="onClick"
  >
    <v-flex shrink>
      <icon-with-badge
        :icon="icon"
        :material-icon="materialIcon"
        :badge="iconBadge"
      />
    </v-flex>
    <v-flex class="label header" :class="{ share: value }">
      {{ label | translate }}
    </v-flex>
    <v-flex class="spacer"/>
    <v-flex v-if="value" class="label value" :class="{ share: value }">
      {{ value | translate }}
    </v-flex>
    <v-flex v-if="!readonly" shrink class="spacer"/>
    <v-flex v-if="!readonly" shrink class="action">
      <icon-button-with-badge
        material-icon="keyboard_arrow_right"
        @click="onClick"
        :badge="actionBadge"
      />
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import IconButtonWithBadge from './IconButtonWithBadge.vue'
import IconWithBadge from './IconWithBadge.vue'

@Component({
  components: {
    IconButtonWithBadge,
    IconWithBadge,
  },
})
export default class ListNavigationItem extends Vue {
  @Prop({ default: '' }) public label: string
  @Prop({ default: '' }) public value: string
  @Prop({ default: '' }) public icon: string
  @Prop({ default: '' }) public materialIcon: string
  @Prop({ default: false }) public active: boolean
  @Prop({ default: false }) public readonly: boolean
  @Prop({ default: '' }) public iconBadge: string
  @Prop({ default: '' }) public actionBadge: string

  @Emit('selected')
  public onClick() {
    return
  }
}
</script>

<style lang='scss' scoped>
.list-navigation-item {
  flex: 0;
  padding: 15px;
  border-bottom: solid 1px rgba(123,139,154,.3);
  &.selected {
    background: #1a3856;
  }
  .label {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;      
  }
  .spacer {
    width: 15px;
  }
  .header {
    text-align: left;
    &.share {
      flex-basis: 150px;
    }
  }
  .value {
    text-align: right;
    color: #7a8b9a;
  }
}
</style>

<style lang="css">
</style>

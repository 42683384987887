<template>
  <v-navigation-drawer class="alarms-drawer"
    v-model="isOpen"
    absolute
    temporary
    right
    dark
    width=250
  >
    <v-layout column>
      <!-- header -->
      <v-flex row class="alarms-header">
        <v-layout row v-if="isServiceDisturbed">
          <v-flex shrink>
            <icon-with-badge class="alarms-header-icon"
              material-icon="alarm"
              :badge="alarms.length"
            />
          </v-flex>
          <v-flex>
            <div class="alarms-header-text">
              {{ 'alarm.service.disturbed' | translate }}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- offline -->
      <v-flex v-offline class="alarm-box">
        <v-layout row>
          <v-flex shrink>
            <icon-with-badge class="alarm-icon"
              material-icon="signal_cellular_off"
              badge="!"
            />
          </v-flex>
          <v-flex>
            <div class="alarm-text">
              {{'app.offline' | translate}}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- alarms -->
      <v-flex v-for="alarm in alarms" :key="alarm.id" class="alarm-box">
        <v-layout row>
          <v-flex shrink>
            <icon-with-badge class="alarm-icon"
              :material-icon="alarm.icon"
              badge="!"
            />
          </v-flex>
          <v-flex>
            <div class="alarm-text">
              {{alarm.title}}<br/>{{ alarm.text }}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <base-text-button @buttonClicked="setDrawerVisible(false)">
        {{'common.button.close' | translate }}
      </base-text-button>
    </v-layout>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import IconWithBadge from './shared/IconWithBadge.vue'

const AlarmUi = namespace('alarmUi')
const Resource = namespace('resource')

@Component({
  components: {
    IconWithBadge,
  },
})
export default class Alarms extends Vue {
  @AlarmUi.Getter public isDrawerVisible: boolean
  @AlarmUi.Getter public isServiceDisturbed: boolean
  @AlarmUi.Getter public alarms
  @AlarmUi.Mutation public setDrawerVisible: (visible: boolean) => void
  @Resource.Getter public dictionary

  public get isOpen() {
    return this.isDrawerVisible
  }

  public set isOpen(value: boolean) {
    this.setDrawerVisible(value)
  }
}
</script>

<style lang='scss' scoped>
.alarms-drawer {
  background: $bg-primary !important;
  padding: 10px;
  text-align: left;
  .alarms-header {
    padding-left: 5px;
    .alarms-header-icon {
      margin-top: 10px;
    }
    .alarms-header-text {
      font-size: 16px;
    }
  }
  .alarm-box {
    margin-top: 10px;
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px;
    .alarm-icon {
      margin-top: 10px;
    }
  }
}
</style>

<style lang="css">
.alarms-drawer .alarms-header .header-title {
  white-space: initial;
}
.alarms-drawer .alarm-header .header-title {
  white-space: initial;
  text-transform: none;
  font-size: 14px;
}
</style>

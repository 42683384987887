<template>
  <div 
    class="border-container"
    :style="getStyle">
    <div class="value-box">        
      {{ valueLabel }}
    </div>
  </div>	
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import Gradients from './../../deviceControls/v1/shared/gradients'

import { EnergyStatusDeviceViewModel } from './../models'

@Component({
  components: {
  },
})
export default class EnergyDeviceValueBox extends Vue {
  @Prop() public device: EnergyStatusDeviceViewModel

  public get getStyle() {
    return {
      background: `linear-gradient(180deg, ${Gradients.primaryColorRgbaFunction(this.gradient, 0.65)}, ${Gradients.secondaryColorRgbaFunction(this.gradient, 0.65)})`,
    }
  }

  public get gradient() {
    return this.device.powerGradient ?? ''
  }

  public get valueLabel() {
    return this.device.powerDisplayValue
  }
}
</script>

<style lang="scss" scoped>
.border-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.value-box {
  width: 99px; 
  height: 29px;
  text-align: center;
  line-height: 30px;
  background: radial-gradient(#1a3856,#080e19);
  font-weight: bold;
}
</style>

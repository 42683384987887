declare global {
  interface Window {
    cordova?: any,
  }
}

import Vue from 'vue'
import App from './App.vue'
import StackRouter from './router'
import store from './store'
import SharedComponents from '@ecocoach/shared-components/src'
import { defaultBackendEnvironment, authenticationRedirectUrl, authenticationOptions } from './environment'
import VueLogger from 'vuejs-logger'
import ApiService, { BackendEnvironmentTypes, BACKEND_ENVIRONMENT_STORAGE_KEY } from '@ecocoach/domain-store-modules/src/services/api.service'
import Vuetify from 'vuetify'
import { longClickDirective } from 'vue-long-click'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { AppAction, AppGetter } from './store/modules/app/types'
import { AuthenticationAction } from '@ecocoach/domain-store-modules/src/authentication/types'
import { ApiErrorConverter } from '@ecocoach/domain-store-modules/src/helpers/apiErrorConverter'
import EnvironmentService from '@ecocoach/domain-store-modules/src/services/environment.service'
import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'
import { AppIdMobileApp } from '@ecocoach/domain-store-modules/src/alarm/types'
import VueScreenSize from 'vue-screen-size'
import { offlineDirective } from './directives/offlineDirective'
import { swipeRightDirective } from './directives/swipeRightDirective'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'typeface-titillium-web/index.css'
import AuthenticationService from '@ecocoach/domain-store-modules/src/services/authentication.service'
import HubService from '@ecocoach/domain-store-modules/src/services/hub.service'
import CacheService from '@ecocoach/domain-store-modules/src/services/cache.service'
import { initializePushNotifications } from './onesignal'
import { matchSubstringCaseInsensitive } from '@ecocoach/domain-store-modules/src/utils'
import { LogLevelStorageKey } from './store/modules/app/models'
import { identifyLogRocket, initializeLogRocket } from './logrocket'

Vue.use(Vuetify)
Vue.use(SharedComponents)
Vue.use(VueScreenSize)
Vue.directive('offline', offlineDirective)
Vue.directive('swipe-right', swipeRightDirective)

Vue.prototype.$isFirefox = matchSubstringCaseInsensitive(navigator.userAgent, 'firefox')
Vue.directive('long-click', longClickDirective({ delay: 500, interval: 0 }))

Vue.config.productionTip = false

// no need to import dictionary in components:
Vue.filter('translate', (value) => store.getters[`resource/${ResourceGetter.dictionary}`](value))

// no need to import svgIcon in components
Vue.filter('resolveIcon', (value) => store.getters[`resource/${ResourceGetter.svgIcon}`](value))

const apiErrorConverter = new ApiErrorConverter({
  resolveStringResource: store.getters[`resource/${ResourceGetter.dictionary}`],
})

if (!window.cordova) {
  initialize(window.location.href)
} else {
  document.addEventListener('deviceready', () => {
    setTimeout(() => {
      document.addEventListener('pause', () => {
        store.dispatch(`app/${AppAction.pause}`)
      }, false)
      document.addEventListener('resume', () => {
        store.dispatch(`app/${AppAction.resume}`)
      }, false)
      initialize(window.location.href)
    }, 0)
  }, false)
}

async function initialize(url: string) {

  const storedBackendEnvironment = await AppDataStorageService.getForAppId(AppIdMobileApp, BACKEND_ENVIRONMENT_STORAGE_KEY) as BackendEnvironmentTypes
  const backendEnvironment = storedBackendEnvironment || defaultBackendEnvironment()
  AppDataStorageService.init({ appId: AppIdMobileApp, environment: backendEnvironment})
  await initializeLogging()

  Vue.$log.info('main.initialize()')

  await initializeLogRocket()

  Vue.$log.info('devicePlatform', EnvironmentService.devicePlatform)
  Vue.$log.info('deviceModel', EnvironmentService.deviceModel)
  Vue.$log.info('url', url)
  Vue.$log.info('window.location.origin', window.location.origin)
  Vue.$log.info('authenticationRedirectUrl', authenticationRedirectUrl())
  Vue.$log.info('defaultBackendEnvironment', defaultBackendEnvironment())
  Vue.$log.info('selectedBackendEnvironment', backendEnvironment)
  Vue.$log.info('process.env.NODE_ENV', process.env.NODE_ENV)
  Vue.$log.info('process.env.APP_VERSION', process.env.APP_VERSION)
  Vue.$log.info('process.env.BUILD_NUMBER', process.env.BUILD_NUMBER)
  Vue.$log.info('setting backend environment', backendEnvironment)
  ApiService.init({ appId: AppIdMobileApp, environment: backendEnvironment })
  Vue.$log.info('authenticationOptions', authenticationOptions(backendEnvironment, initialize))
  ApiService.registerErrorHandler(errorContext => store.dispatch(`app/${AppAction.handleApiError}`, apiErrorConverter.convert(errorContext)))

  HubService.registerReconnectingHandler(({ hubId }) => store.dispatch(`app/${AppAction.handleHubReconnecting}`, hubId))
  HubService.registerReconnectedHandler(({ hubId }) => store.dispatch(`app/${AppAction.handleHubReconnected}`, hubId))
  HubService.setLogCallback(Vue.$log.info)
  AuthenticationService.setLogCallback(Vue.$log.info)

  await store.dispatch(`authentication/${AuthenticationAction.initialize}`, authenticationOptions(backendEnvironment, initialize))
  await store.dispatch(`authentication/${AuthenticationAction.handleAppLoad}`, url)

  CacheService.init({ userIdentifier: store.getters[`app/${AppGetter.userIdentifier}`], environment: backendEnvironment, version: 1})

  identifyLogRocket()
  
  initializePushNotifications()

  if (!window.frameElement) {
    new Vue({
      router: StackRouter.router,
      store,
      render: (h) => h(App),
    }).$mount('#app')
  }
}

async function initializeLogging() {
  const storedLogLevel = await AppDataStorageService.get(LogLevelStorageKey)
  const logLevel = storedLogLevel || (process.env.NODE_ENV === 'production' ? 'error' : 'info')
  await AppDataStorageService.set(LogLevelStorageKey, logLevel)
  Vue.use(VueLogger as any, {
    logLevel,
    showLogLevel: true,
    showMethodName: true,
    showConsoleColors: true,
  })
}

import { render, staticRenderFns } from "./UserAgreements.vue?vue&type=template&id=d02f0c00&scoped=true"
import script from "./UserAgreements.vue?vue&type=script&lang=ts"
export * from "./UserAgreements.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.9.1_prettier@2.8.8_vue-template-compiler@2.6.6_webpack@5.90.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d02f0c00",
  null
  
)

export default component.exports
import { Module } from 'vuex'
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'
import { AlarmUiGetter, AlarmUiMutation, AlarmUiState } from './types'
import { RootState } from '@/store/types'

const namespaced: boolean = true

export const alarmUi: Module<AlarmUiState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
}

export const types = {
  mutation: AlarmUiMutation,
  getter: AlarmUiGetter,
}

export const domain = 'alarmUi'

export const vuexModule = {
  [domain]: alarmUi,
}

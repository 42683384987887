<template>
  <app-view>
    <div class="support">
      <div class="support-information">
        <div> 
          <p> {{ 'support.help' | translate }}</p>
        </div>
      </div>
    </div>
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import AppView from './AppView.vue'

@Component({
  components: {
    AppView,
  },
})
export default class SupportContact extends Vue {
}
</script>
<style lang="scss" scoped>
.support{
  margin-top: 60px;
  padding: 30px;
  font-size: 16px;
  .support-information {
    div {
      padding-bottom: 20px;
    }
  }
}
</style>
import { ChartModel } from '@ecocoach/domain-store-modules/src/chart/models'

export interface ChartCollectionTab {
  id: string
  text: string
  icon?: string
  materialIcon?: string
  charts: ChartTab[]
}

export interface ChartTab extends ChartModel {
  text: string
  descriptionHtml: string
}

export interface ChartContext {
  seriesVisible: (id: string) => boolean
  seriesVisibilityToggled: (id: string) => void
  resolveStringResource: (resourceKey: string) => string
}

export const StatusViewChartCollection = 'Status'

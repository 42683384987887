import { AlarmLevel } from '@ecocoach/domain-store-modules/src/common'

export interface AlarmViewModel {
  id: string
  title: string
  icon: string
  text: string
  type: AlarmType
  level?: AlarmLevel
}

export enum AlarmType {
  ServiceAlarm = 'ServiceAlarm',
  MessageAlarm = 'MessageAlarm',
  CustomPlcAlarm = 'CustomPlcAlarm',
  OfflinePlcAlarm = 'OfflinePlcAlarm',
}

<template>
  <app-view>
    <icon-header
      :title="'navigation.useragreements' | translate"
      material-icon="menu_book"
    />
    <list-navigation-item v-for="userAgreement in userAgreements" :key="userAgreement.type"
      :label="userAgreement.name"
      @selected="navigateToUserAgreement(userAgreement.type)"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'
import { UserAgreementViewModel } from '../store/modules/app/models'
import StackRouter from '../router'

const App = namespace('app')

@Component({
  components: {
    AppView,
    IconHeader,
    ListNavigationItem,
  },
})
export default class UserAgreements extends Vue {
  @App.Getter public userAgreements: UserAgreementViewModel[]

  public navigateToUserAgreement(type: string) {
    Vue.$log.info('Navigating to user agreement', type)
    StackRouter.navigate({ name: 'useragreement', params: { type } })
  }
}
</script>

<style lang="scss" scoped>
</style>
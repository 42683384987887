import { GetterTree } from 'vuex'
import { SceneUiGetter, SceneUiState } from './types'
import { RootState } from '@/store/types'
import { SceneViewModel, ScheduleType, SelectOption, SceneDetailsViewModel } from './models'
import { DaysEnum, DeviceModel, RoomModel, ControlModelV2, PlcModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { deepCompare } from '@ecocoach/domain-store-modules/src/utils'
import { getSchedule, isActivating, scheduleActive, scheduleText, scheduleType, defaultScheduleDateTime, scheduleOfSceneIsValid } from './helpers'
import moment from 'moment'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { sortedRooms, sortedDevices, usedDeviceIdsOfScene, getVisibleControlsSorted } from '@/store/utils'
import { PlcOperationGetter } from '@ecocoach/domain-store-modules/src/plcOperation/types'

export const getters: GetterTree<SceneUiState, RootState> = {
  [SceneUiGetter.scene]({ scene, activatingSceneIds, dateTimeUtcNow }, __, rootState, rootGetters): SceneDetailsViewModel {
    const schedule = getSchedule(scene)
    const scheduleDateTime = (schedule && schedule.triggerDateTime)
      ? moment.utc(schedule.triggerDateTime).toISOString()
      : defaultScheduleDateTime()
    return {
      id: scene.id,
      name: scene.name,
      icon: scene.iconResourceId,
      gradient: scene.colorGradient,
      isActivating: isActivating(scene, activatingSceneIds),
      scheduleValid: scheduleOfSceneIsValid(scene, dateTimeUtcNow),
      scheduleActive: scheduleActive(scene, dateTimeUtcNow),
      scheduleText: scheduleText(scene, dateTimeUtcNow, rootGetters[`resource/${ResourceGetter.dictionary}`]),
      selectedDeviceIds: usedDeviceIdsOfScene(scene, rootState.plcOperation.controlsLookup),
      scheduleId: schedule && schedule.id || '',
      scheduleEnabled: schedule && schedule.isEnabled || false,
      scheduleTriggerDateTime: scheduleDateTime,
      scheduleType: scheduleType(scene),
      scheduleRecurringDays: schedule && schedule.days || [],
      scheduleLocalDate: moment.utc(scheduleDateTime).local().format('YYYY-M-D'),
      scheduleLocalTime: moment.utc(scheduleDateTime).local().format('HH:mm'),
      controlCommands: scene.controlCommands,
    } as SceneDetailsViewModel
  },
  [SceneUiGetter.scenes]({ activatingSceneIds, dateTimeUtcNow }, __, rootState, rootGetters): SceneViewModel[] {
    return rootState.plcOperation.scenes
      .filter(scene => scene.projectId === rootState.app.selectedProjectId)
      .map(scene => {
        return {
          id: scene.id,
          name: scene.name,
          icon: scene.iconResourceId,
          gradient: scene.colorGradient,
          isActivating: isActivating(scene, activatingSceneIds),
          scheduleValid: scheduleOfSceneIsValid(scene, dateTimeUtcNow),
          scheduleActive: scheduleActive(scene, dateTimeUtcNow),
          scheduleText: scheduleText(scene, dateTimeUtcNow, rootGetters[`resource/${ResourceGetter.dictionary}`]),
        } as SceneViewModel
      }).sort((a, b) => a.name.localeCompare(b.name))
  },
  [SceneUiGetter.plcs](_, getter, rootState): PlcModel[] {
    return rootState.plcOperation.plcs.filter(p =>
      p.projectId === rootState.app.selectedProjectId &&
      !!getter.roomsOfPlc(p.id).length)
  },
  [SceneUiGetter.roomsOfPlc](_, getter, rootState) {
    return (plcId: string): RoomModel[] => {
      const rooms = rootState.plcOperation.roomsForPlcLookup.get(plcId) || []
      return sortedRooms(rooms)
        .filter(r => !!getter.devicesOfRoom(r.id).length)
    }
  },
  [SceneUiGetter.devicesOfRoom](_, getter, rootState) {
    return (roomId: string): DeviceModel[] => {
      const devices = rootState.plcOperation.devicesForRoomLookup.get(roomId) || []
      return sortedDevices(devices)
        .filter(d => !!getter.controlsOfDevice(d.id).length)
    }
  },
  [SceneUiGetter.checkedDevicesOfRoom]({ scene }, getter, rootState) {
    return (roomId: string): DeviceModel[] => {
      const checkedDeviceIds = usedDeviceIdsOfScene(scene, rootState.plcOperation.controlsLookup)
      return getter.devicesOfRoom(roomId)
        .filter(device => checkedDeviceIds.includes(device.id))
    }
  },
  [SceneUiGetter.controlsOfDevice](_, __, ___, rootGetters) {
    return (deviceId: string): ControlModelV2[] => {
      const controls = rootGetters[`plcOperation/${PlcOperationGetter.filteredControls}`]
      return getVisibleControlsSorted(controls, true).filter(c => c.deviceId === deviceId)
    }
  },
  [SceneUiGetter.sceneSavable]({ scene, originalScene }): boolean {
    return !!scene.name && !!scene.controlCommands.length && !deepCompare(scene, originalScene)
  },
  [SceneUiGetter.scheduleTypeOptions](_, __, ___, rootGetters): SelectOption[] {
    const stringResource = rootGetters[`resource/${ResourceGetter.dictionary}`]
    return Object.values(ScheduleType).map(value => ({
      value,
      label: stringResource(`scenes.schedule.${value}`),
    }))
  },
  [SceneUiGetter.scheduleDaysOptions](_, __, ___, rootGetters): SelectOption[] {
    const stringResource = rootGetters[`resource/${ResourceGetter.dictionary}`]
    return Object.values(DaysEnum).map(value => ({
      value,
      label: stringResource(`scenes.schedule.${value.toLowerCase()}`),
    }))
  },
  [SceneUiGetter.dateTimeUtcNow]({ dateTimeUtcNow }): string {
    return dateTimeUtcNow
  },
}

import { SceneModel, DeviceModel, RoomModel, ControlModelV2 } from '@ecocoach/domain-store-modules/src/plcOperation/models'

export const sortedRooms = (rooms: RoomModel[]) => {
  return [...rooms].sort((first, second) => first.sortOrder - second.sortOrder)
}

export const sortedDevices = (devices: DeviceModel[]) => {
  return [...devices].sort((first, second) => first.sortOrder - second.sortOrder)
}

export const appDisplayLevel = (control: ControlModelV2) => {
  return control.attributes?.appDisplayLevel ?? ''
}

export const energyDisplayLevel = (control: ControlModelV2) => {
  return control.attributes?.energyDisplayLevel ?? ''
}

export const configurationToolCategory = (control: ControlModelV2) => {
  return control.attributes?.configurationToolCategory ?? ''
}

// applies visibility condition filter and does advanced sorting
export const getVisibleControlsSorted = (controls: ControlModelV2[], ignoreVisibilityCondition: boolean = false) => {
  const childFilter = (c: ControlModelV2) => !c.parentControlId
  const visibilityConditionFilter = (c: ControlModelV2) => ignoreVisibilityCondition || 
    !c?.attributes?.visibilityCondition ||
    c.attributes.visibilityCondition.value === c.state![c.attributes.visibilityCondition.state]
  return controls
    .filter(control => childFilter(control) && visibilityConditionFilter(control))
    .sort((first, second) => {
      // first sort by configurationToolCategory (inverse enum string sorting: state > parameter > functiontest)
      const configurationToolCategorySortResult = configurationToolCategory(second).localeCompare(configurationToolCategory(first))
      // then sort by sortOrder
      return configurationToolCategorySortResult !== 0 ? configurationToolCategorySortResult : first.sortOrder - second.sortOrder
    })
}

export const usedDeviceIdsOfScene = (scene: SceneModel, controlsMap: Map<string, ControlModelV2>) => {
  const controls = scene.controlCommands.map(sc => controlsMap.get(sc.controlId)!).filter(_ => _)
  return Array.from(new Set(controls.map(c => c.deviceId)))
}

<template>
  <app-view>
    <v-layout column align-center class="container">
      <svg-icon class="banner" :icon="'eco.banner.switzerland.white'" :width="250" :height="70"/>
      <div class="title-area">
        <p>ecocoach Smart Energy</p>
      </div>
      <div class="info-area">
        <h3>{{ 'app.welcome' | translate }}</h3>
        <p>{{ 'app.welcome.introduction' | translate }}</p>
        <base-text-button @buttonClicked="navigateToSettings">{{ 'common.button.next' | translate }}</base-text-button>
      </div>
    </v-layout>
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import AppView from './AppView.vue'
import SvgIcon from './shared/SvgIcon.vue'
import StackRouter from '../router'

@Component({
  components: {
    AppView,
    SvgIcon,
  },
})
export default class Welcome extends Vue {
  public navigateToSettings() {
    Vue.$log.info('Navigating to more')
    StackRouter.navigate({ name: 'more' })
  }
}
</script>
<style lang="scss" scoped>
.container {
  margin-top: 40px;
  font-size: 16px;
  .title-area {
    margin-bottom: 20px;
  }
  .banner {
    margin-bottom: 20px;
  }
  .info-area {
    margin-bottom: 20px;
  }
  .v-btn {
    border: 1px solid white;
  }
}
</style>
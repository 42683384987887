<template>
  <app-view>
    <template v-slot:actions>
      <icon-button
        @buttonClicked="showDeleteConfirmationDialog = true"
        type="materialIcon"
        materialIconName="delete"
        :height="20"
        :width="20"
        :enabled="!isInteracted && !!scene.id"
      />
      <icon-button
        @buttonClicked="onSaveScene"
        type="materialIcon"
        materialIconName="save"
        :height="20"
        :width="20"
        :enabled="!isInteracted && sceneSavable"
      />
    </template>
    <div class="scene">
      <v-layout row align-center justify-space-between class="scene-row">
        <v-flex shrink class="scene-icon" @click="showSceneIconSelector = true" >
          <icon-with-gradient v-ripple
            :icon="scene.icon"
            :gradient="scene.gradient"
          />
        </v-flex>
        <v-flex shrink class="scene-spacer"/>
        <v-flex grow class="scene-name" >
          <text-input 
            :value="sceneName"
            :placeholder="'scenes.scene.name.placeholder' | translate"
            @input="onNameChanged($event)"
          />
        </v-flex>
      </v-layout>
      <!-- controls -->
      <div v-for="plc in plcs" :key="plc.id" class="plc-list">
        <div class="plc">
          <icon-header v-if="plcs.length > 1" class="plc-name"
            :title="plc.name"
            :icon="'eco.home.white'"
          />
          <v-layout row wrap class="room-list">
            <expandable-panel v-for="room in roomsOfPlc(plc.id)" class="room"
              :key="room.id" 
              :icon="room.icon"
              :title="room.name"
              :badge="roomBadge(room.id)"
              :open="expandedRooms.includes(room.id)"
            >
              <expandable-panel v-for="device in devicesOfRoom(room.id)" class="device"
                :key="device.id" 
                :icon="device.icon"
                :title="device.name"
                :expandable="false"
                :open="isDeviceChecked(device)"
                :checkbox="true"
                :checked="isDeviceChecked(device)"
                @onChecked="onDeviceChecked(device)"
              >
                <div class="device-controls" v-if="isDeviceChecked(device)">
                  <scene-control v-for="control in controlsOfDevice(device.id)"
                    :key="control.id"
                    :control="control"
                    :scene="scene"
                  />
                </div>
              </expandable-panel>
            </expandable-panel>
          </v-layout>
        </div>
      </div>
      <div v-if="plcs.length === 0">
        {{ 'rooms.detail.no_items' | translate }}
      </div>
    </div>
    <icon-picker-dialog 
      :show="showSceneIconSelector"
      :icons="icons"
      :gradients="gradients"
      :selected-icon="scene.icon"
      :selected-gradient="scene.gradient"
      @gradientSelected="setSceneGradient"
      @iconSelected="setSceneIcon"
      @closed="showSceneIconSelector = false"
    />
    <confirm-dialog
      :show="showDeleteConfirmationDialog"
      :header="'common.button.delete' | translate"
      :text="'common.dialog.confirm' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      @confirmed="onDeleteScene"
      @cancelled="showDeleteConfirmationDialog = false"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import StackRouter from '../router'
import { Gradients } from '@ecocoach/shared-components/src'
import ExpandablePanel from './shared/ExpandablePanel.vue'
import IconHeader from './shared/IconHeader.vue'
import IconWithGradient from './shared/IconWithGradient.vue'
import IconPickerDialog from './shared/IconPickerDialog.vue'
import SceneControl from './shared/SceneControl.vue'
import { ControlModelV2, DeviceModel, PlcModel, RoomModel } from '../../../eco-domain-store-modules/src/plcOperation/models'
import { ResourceModel, ResourceCategory } from '../../../eco-domain-store-modules/src/resource/models'
import { SceneDetailsViewModel } from '../store/modules/sceneUi/models'
import { debounce } from 'debounce'

const App = namespace('app')
const DeviceUi = namespace('deviceUi')
const SceneUi = namespace('sceneUi')
const Resource = namespace('resource')

@Component({
  components: {
    AppView,
    ExpandablePanel,
    IconHeader,
    IconWithGradient,
    IconPickerDialog,
    SceneControl,
  },
})
export default class Scene extends Vue {
  @App.Getter public isInteracted: boolean
  @SceneUi.Getter public scene: SceneDetailsViewModel
  @SceneUi.Getter public plcs: PlcModel[]
  @SceneUi.Getter public roomsOfPlc: (plcId: string) => RoomModel[]
  @SceneUi.Getter public devicesOfRoom: (roomId: string) => DeviceModel[]
  @SceneUi.Getter public checkedDevicesOfRoom: (roomId: string) => DeviceModel[]
  @SceneUi.Getter public controlsOfDevice: (deviceId: string) => ControlModelV2[]
  @SceneUi.Getter public sceneSavable: boolean
  @SceneUi.Mutation public setSceneName: (name: string) => void
  @SceneUi.Mutation public setSceneIcon: (name: string) => void
  @SceneUi.Mutation public setSceneGradient: (name: string) => void
  @SceneUi.Action public loadScene: (sceneId?: string) => Promise<void>
  @SceneUi.Action public deleteScene: () => Promise<void>
  @SceneUi.Action public createScene: () => Promise<void>
  @SceneUi.Action public saveScene: () => Promise<void>
  @SceneUi.Action public addDeviceToScene: (deviceId: string) => Promise<void>
  @SceneUi.Action public removeDevicefromScene: (deviceId: string) => Promise<void>
  @DeviceUi.Action public setControlsFilterForScene: () => Promise<void>
  @Resource.Getter public resourcesOfCategory: (category: ResourceCategory) => ResourceModel[]

  public showDeleteConfirmationDialog = false
  public showSceneIconSelector = false
  public expandedRooms: string[] = []

  public onNameChangedDebounceTime: number = 500
  public onNameChanged = debounce((text) => this.setSceneName(text), this.onNameChangedDebounceTime)

  public beforeMount() {
    this.setControlsFilterForScene()
    this.loadScene(this.sceneId)
    this.expandedRooms = this.plcs
      .map(plc => this.roomsOfPlc(plc.id).filter(r => !!this.checkedDevicesOfRoom(r.id).length).map(r => r.id))
      .reduce((a, b) => a.concat(b), [])
  }

  public get sceneId(): string {
    return this.$route.params.sceneId
  }

  public get gradients(): string[] {
    return Gradients.list().map(gradient => gradient.id)
  }

  public get icons(): string[] {
    return this.resourcesOfCategory(ResourceCategory.SCENE_ICONS).map(icon => icon.id)
  }

  public get sceneName(): string {
    return this.scene.name
  }

  public isDeviceChecked(device: DeviceModel) {
    return this.scene.selectedDeviceIds.includes(device.id)
  }

  public hasRoomCheckedDevices(roomId: string) {
    return !!this.checkedDevicesOfRoom(roomId).length
  }

  public roomBadge(roomId: string) {
    const numCheckedDevices = this.checkedDevicesOfRoom(roomId).length
    return numCheckedDevices ? numCheckedDevices.toString() : ''
  }

  public onDeviceChecked(device: DeviceModel) {
    if (this.isDeviceChecked(device)) {
      this.removeDevicefromScene(device.id)
    } else {
      this.addDeviceToScene(device.id)
    }
  }

  public async onDeleteScene() {
    await this.deleteScene()
    StackRouter.navigate({ name: 'scenes' })
  }

  public async onSaveScene() {
    if (this.scene.id) {
      await this.saveScene()
    } else {
      await this.createScene()
    }
    StackRouter.navigate({ name: 'scenes' })
  }
}

</script>

<style lang='scss' scoped>
.plc-list {
  margin-bottom: 20px;
  padding: 0px 5px 0px 15px;
}
.plc-name {
  margin: 0px 15px;
}
.scene {
  .scene-row {
    padding: 5px 15px;
    .scene-name {
      font-size: 16px;
    }
    .scene-spacer {
      min-width: 10px;
    }
  }
  .room {
    padding-right: 10px;
    .device {
      padding: 0px 0px 15px 30px;
      .device-controls {
        padding-left: 15px;
      }
    }
  }
}

</style>
<style lang="css">

</style>

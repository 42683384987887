import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { EnergyStatusUiAction } from './types'
import { EnergyStatusAction } from '@ecocoach/domain-store-modules/src/energyStatus/types'

export const actions: ActionTree<{}, RootState> = {
  async [EnergyStatusUiAction.startStatusViewUpdates]({ dispatch }): Promise<void> {
    await dispatch(`energyStatus/${EnergyStatusAction.setEnergyStatusItemsFilter}`, () => true, { root: true })
  },
  async [EnergyStatusUiAction.stopStatusViewUpdates]({ dispatch }): Promise<void> {
    await dispatch(`energyStatus/${EnergyStatusAction.setEnergyStatusItemsFilter}`, () => false, { root: true })
  },
}

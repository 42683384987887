import { RawLocation } from 'vue-router'

export interface TabModel {
  id: string
  text: string
  icon: string
}

export interface UserAgreementViewModel {
  type: string
  version: string
  accepted: boolean
  name: string
  content: string
}

export class ToastModel {
  public static info(message: string): ToastModel {
    const toast = new ToastModel()
    toast.type = 'info'
    toast.message = message
    return toast
  }

  public static warning(message: string): ToastModel {
    const toast = new ToastModel()
    toast.type = 'warning'
    toast.message = message
    return toast
  }

  public static error(message: string): ToastModel {
    const toast = new ToastModel()
    toast.type = 'error'
    toast.message = message
    return toast
  }
  public get color(): string {
    switch (this.type) {
    case 'info': return '#1a3856' // dark blue
    case 'warning': return '#1a3856' // dark blue
    case 'error': return '#fa0f36' // red
    default: return ''
    }
  }
  public get timeout(): number {
    return this.type !== 'error' ? 6000 : 0
  }

  public message: string

  private type: 'info' | 'warning' | 'error'
}

export interface AppVersionInfo {
  currentVersion: string
  minimumVersion: string
}

export enum PushNotificationType {
  PlcOffline = 'plcOffline',
  PlcOnline = 'plcOnline',
  CustomAlarmOpened = 'customAlarmOpened',
  CustomAlarmClosed = 'customAlarmClosed',
  CustomAlarmOfflineClosed = 'customAlarmOfflineClosed',
}

export interface PushNotificationOpenedPayload {
  notificationType: PushNotificationType
  projectId: string
  objectId: string
  navigate: (location: RawLocation, replaceHistoryStack?: string[]) => Promise<void>
}

export const ProjectIdStorageKey = 'projectId'
export const LanguageStorageKey = 'language'
export const IncludeDemoProjectsStorageKey = 'includeDemoProjects'
export const ChartCollectionIdStorageKey = 'chartCollectionId'
export const ChartIdStorageKey = 'chartId'
export const ChartNodeIdStorageKey = 'chartNodeId'
export const ChartScalingStorageKey = 'chartScaling'
export const ShareDebugInfoStorageKey = 'shareDebugInfo'
export const ShareDebugInfoActivationTimeStampStorageKey = 'shareDebugInfoActivationTimeStamp'
export const LogLevelStorageKey = 'logLevel'

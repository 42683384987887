import ApiService from '@ecocoach/domain-store-modules/src/services/api.service'
import { AppVersionInfo } from './models'
import EnvironmentService from '@ecocoach/domain-store-modules/src/services/environment.service'
import { AppIdMobileApp } from '@ecocoach/domain-store-modules/src/alarm/types'

export default {
  async appVersionInfo(): Promise<AppVersionInfo> {
    const response = await ApiService.get(
      `${ApiService.backendEnvironmentConfiguration().userSettingsApi}/Users/appVersion/${AppIdMobileApp}/${EnvironmentService.devicePlatform}`)
    return response.data
  },
  async initiateAccountDeletion(): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().userSettingsApi}/Accounts/initiateAccountDeletion`, {})
  },
}

<template>
  <app-view :loading="loading">
    <div v-for="plc in favoritePlcs" :key="plc.id" class="plc-list">
      <div class="plc">
        <icon-header v-if="favoritePlcs.length > 1" class="plc-name"
          :title="plc.name"
          :icon="'eco.home.white'"
        />
        <v-layout row wrap align-start class="device-list">
          <device-box v-for="device in favoriteDevicesOfPlc(plc.id)" :key="device.id">
            <v-layout>
              <v-flex shrink class="device-room">
                <div class="device-room-name">
                  {{roomName(device.roomId)}}
                </div>
              </v-flex>
              <v-flex grow class="device-content">
                <div class="device-header" @click="navigateToDevice(device.id)" v-ripple>
                  <svg-icon class="device-icon" :icon="device.icon" :width="35" :height="35"/>
                  <div class="device-name">{{ device.name }}</div>
                  <base-material-icon iconName="keyboard_arrow_right" :height="20" />
                </div>
                <div class="device-controls">
                  <device-control v-for="control in favoriteControlsOfDevice(device.id)"
                    :key="control.id"
                    :control="control"
                  />
                </div>
              </v-flex>
            </v-layout>
          </device-box>
        </v-layout>
      </div>
    </div>
    <div v-if="favoritePlcs.length === 0">
      {{ 'favorites.no_items' | translate }}
    </div>
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import DeviceBox from './shared/DeviceBox.vue'
import DeviceControl from './shared/DeviceControl.vue'
import IconHeader from './shared/IconHeader.vue'
import SvgIcon from './shared/SvgIcon.vue'
import StackRouter from '../router'
import { ControlModelV2, DeviceModel, PlcModel, RoomModel } from '../../../eco-domain-store-modules/src/plcOperation/models'

const DeviceUi = namespace('deviceUi')

@Component({
  components: {
    AppView,
    DeviceBox,
    DeviceControl,
    IconHeader,
    SvgIcon,
  },
})
export default class Favorites extends Vue {
  @DeviceUi.Getter public loading: boolean
  @DeviceUi.Getter public favoritePlcs: PlcModel[]
  @DeviceUi.Getter public favoriteDevicesOfPlc: (plcId: string) => DeviceModel[]
  @DeviceUi.Getter public favoriteControlsOfDevice: (deviceId: string) => ControlModelV2[]
  @DeviceUi.Getter public roomById: (roomId: string) => RoomModel
  @DeviceUi.Action public startControlUpdatesForFavorites: () => Promise<void>

  public async mounted() {
    this.startControlUpdatesForFavorites()
  }

  public roomName(roomId: string): string {
    return this.roomById(roomId).name
  }

  public navigateToDevice(deviceId: string) {
    Vue.$log.info('Navigating to favoritedevice', deviceId)
    StackRouter.navigate({ name: 'favoritedevice', params: { deviceId } })
  }
}
</script>
<style lang='scss' scoped>
.plc-list {
  margin-bottom: 20px;
}
.plc-name {
  margin: 0px 20px;
}
.device-list {
  .device-room {
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, rgb(45, 52, 66), rgb(145, 161, 182));
    .device-room-name {
      width: 100%;
      max-height: 100%;
      padding: 5px 0px;
      margin-left: -5px;
      font-size: 13px;
      writing-mode: tb-rl;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform: rotate(-180deg);
    }
  }
  .device-content {
    max-width: calc(100% - 15px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .device-header {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      .device-icon {
        flex: 0;
        margin-right: 25px;
      }
      .device-name {
        flex: 1;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
        text-overflow: ellipsis;
        margin-top: 5px;
        max-width: 100%;
      }
    }
    .device-controls {
      padding: 0px 15px;
      display: flex;
      flex-direction: column;
      margin-left: 60px;
    }
  }
}
</style>
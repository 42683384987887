<template>
  <app-view>
    <icon-header
      :title="'Developer settings'"
      material-icon="bug_report"
    />
    <list-navigation-item
      label="Backend environment"
      :value="selectedBackendEnvironment"
      @selected="navigateTo('environment')"
    />
    <list-navigation-item
      label="Log level"
      :value="selectedLogLevel"
      @selected="navigateTo('loglevel')"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import StackRouter from '../router'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'
import { Services } from '@ecocoach/domain-store-modules'
import AppDataStorageService from '../../../eco-domain-store-modules/src/services/appdatastorage.service'
import { LogLevelStorageKey } from '../store/modules/app/models'

@Component({
  components: {
    AppView,
    IconHeader,
    ListNavigationItem,
  },
})
export default class Developer extends Vue {
  public selectedLogLevel = ''

  public async mounted() {
    this.selectedLogLevel = await AppDataStorageService.get(LogLevelStorageKey)
  }

  public get selectedBackendEnvironment(): string {
    return Services.ApiService.selectedBackendEnvironment()
  }

  public navigateTo(routeName: string) {
    Vue.$log.info('Navigating to', routeName)
    StackRouter.navigate({ name: routeName })
  }
}
</script>
<style lang="scss" scoped>
</style>
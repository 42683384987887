import { Module } from 'vuex'
import { actions } from './actions'
import { getters } from './getters'
import { RootState } from '@/store/types'
import { EnergyStatusUiAction, EnergyStatusUiGetter } from './types'

const namespaced: boolean = true

export const energyStatusUi: Module<{}, RootState> = {
  namespaced,
  actions,
  getters,
}

export const types = {
  action: EnergyStatusUiAction,
  getter: EnergyStatusUiGetter,
}

export const domain = 'energyStatusUi'

export const vuexModule = {
  [domain]: energyStatusUi,
}

<template>
  <div v-if="contributions.length" 
    class="percentage-container">
    <div v-for="contribution in contributions"
      :key="contribution.id"
      :style="getStyle(contribution)"
      class="single-value"/>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import Gradients from './../../deviceControls/v1/shared/gradients'

import { ContributionPercentageViewModel } from './../models'

@Component({
  components: {
  },
})
export default class SummaryLine extends Vue {
  @Prop() public contributions: ContributionPercentageViewModel[]

  public getStyle(contribution: ContributionPercentageViewModel) {
    const gradient = this.gradient(contribution)
    return {
      backgroundImage: `-webkit-linear-gradient(right, ${Gradients.secondaryColorRgbaFunction(gradient, 1)} 0%, ${Gradients.primaryColorRgbaFunction(gradient, 1)}  100%)`,
      width: ((contribution.percentageValue ?? 0) * 100) + '%',
    }
  }

  public gradient(contribution: ContributionPercentageViewModel) {
    return contribution.gradient ?? ''
  }
}
</script>

<style lang="scss" scoped>
.percentage-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: black;
}
</style>

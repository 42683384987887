export interface DeviceUiState {
  loading: boolean
}

export enum DeviceUiAction {
  startControlUpdatesForFavorites = 'startControlUpdatesForFavorites',
  startControlUpdatesForRoom = 'startControlUpdatesForRoom',
  startControlUpdatesForDevice = 'startControlUpdatesForDevice',
  setControlsFilterForScene = 'setControlsFilterForScene',
  stopControlUpdates = 'stopControlUpdates',
  
  createFavorite = 'createFavorite',
  deleteFavorite = 'deleteFavorite',
}

export enum DeviceUiMutation {
  setLoading = 'setLoading',
}

export enum DeviceUiGetter {
  loading = 'loading',

  // lookups
  roomById = 'roomById',
  deviceById = 'deviceById',
  controlById = 'controlById',
  isFavoriteDevice = 'isFavoriteDevice',

  // favorites
  favoritePlcs = 'favoritePlcs',
  favoriteDevicesOfPlc = 'favoriteDevicesOfPlc',
  favoriteControlsOfDevice = 'favoriteControlsOfDevice',

  // rooms
  roomPlcs = 'roomPlcs',
  roomsOfPlc = 'roomsOfPlc',

  // room
  roomDevicesOfRoom = 'roomDevicesOfRoom',
  roomControlsOfDevice = 'roomControlsOfDevice',

  // device detail
  deviceControlsOfDevice = 'deviceControlsOfDevice',
}

import { GradientGetter } from './types'
import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { Gradients } from '@ecocoach/shared-components/src'

export const getters: GetterTree<{}, RootState> = {
  [GradientGetter.primaryColor]() {
    return (gradientId: string) => Gradients.primaryColor(gradientId)
  },
  [GradientGetter.secondaryColor]() {
    return (gradientId: string) => Gradients.secondaryColor(gradientId)
  },
  [GradientGetter.gradientPrimaryRgb]() {
    return (gradientId: string) => Gradients.primaryColorRgbFunction(gradientId)
  },
  [GradientGetter.gradientSecondaryRgb]() {
    return (gradientId: string) => Gradients.secondaryColorRgbFunction(gradientId)
  },
  [GradientGetter.gradientPrimaryRgba]() {
    return (gradientId: string, alpha: number) => Gradients.primaryColorRgbaFunction(gradientId, alpha)
  },
  [GradientGetter.gradientSecondaryRgba]() {
    return (gradientId: string, alpha: number) => Gradients.secondaryColorRgbaFunction(gradientId, alpha)
  },
}

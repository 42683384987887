<template>
  <v-layout row align-center justify-content>
    <v-flex v-for="tab in tabs" :key="tab.id"
      class="tab-header"
      :class="{ 
        'selected': tab.id === selectedTab,
        'disabled': tab.disabled,
      }"
      v-ripple="!tab.disabled"
      @click="onTabSelected(tab.id)"
    >
      <v-layout row align-center justify-center fill-height>
        <v-flex shrink class="header-title" :class="{ 'disabled': tab.disabled }"
        >
          {{tab.text}}
        </v-flex>
        <v-flex v-if="tab.descriptionHtml && tab.id === selectedTab" shrink class="header-icon">
          <description-popover
            class="popover-button"
            :header="tab.text"
            :content="tab.descriptionHtml"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class TabHeaders extends Vue {
  @Prop() public tabs: Array<{ id: string, text: string, icon: string, descriptionHtml: string, disabled: boolean }>
  @Prop() public selectedTab: string
  @Prop() public disabled: string

  public onTabSelected(id: string) {
    if (!this.disabled && !this.tabs.find(_ => _.id === id)?.disabled ) {
      this.onChanged(id)
    }
  }

  @Emit('changed')
  public onChanged(id: string) {
    return id
  }
}
</script>
<style lang='scss' scoped>
.tab-header {
  height: 35px;
  background: linear-gradient(#1e3c5e,#0c1422);
  cursor: pointer;
  &.selected {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background: linear-gradient(#1f3c5e,#1c385a);
    box-shadow: 0 -1px 8px rgba(0,0,0,.5);
  }
  &.disabled {
    cursor: default;
  }
  .header-title {
    text-align: center;
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;      
    padding: 0 10px;
    text-transform: uppercase;
    &.disabled {
      opacity: 0.5;
    }
  }
}
</style>

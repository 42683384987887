<template>
  <div class="device-container">
    <div v-if="!rotate" class="status-view-device">
      <div class="device-header">
        <energy-icon-circle
          :device="device"
          :resolve-icon="resolveIcon"
          class="device-bubble"
          @click="onSelected"
        />
      </div>
      <div class="device-footer">
        <div
          :style="gradientBackgroundStyle" 
          class="device-flow">
          <div class="name-container-top">
            <div class="device-name">
              {{ device.label }}
            </div>
          </div>
          <div class="value-and-arrow-container">
            <div class="arrow-container">
              <svg-icon v-show="arrow"
                :icon="arrow" 
                :resolve-icon="resolveIcon"
                height="20" 
                width="20"
              />
            </div>
            <div class="value-container">
              <energy-device-value-box
                :device="device"
                :rotate="rotate"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div 
      v-if="rotate" 
      class="status-view-device">
      <div class="device-footer rotate">
        <div
          :style="gradientBackgroundStyle" 
          class="device-flow">
          <div class="value-and-arrow-container">
            <div class="value-container">
             <energy-device-value-box
              :device="device"
              :rotate="rotate"
            />
            </div>
            <div class="arrow-container">
              <svg-icon v-show="arrow"
                :icon="arrow"
                height="20"
                width="20"
                :resolve-icon="resolveIcon"
              />
            </div>
          </div>
          <div class="name-container-bottom">
            <div class="device-name">
              {{ device.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="device-header rotate">
        <energy-icon-circle
          :device="device"
          :resolve-icon="resolveIcon"
          class="device-bubble"
          @click="onSelected"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Gradients from './../../deviceControls/v1/shared/gradients'

import EnergyDeviceValueBox from './EnergyDeviceValueBox.vue'
import EnergyIconCircle from './EnergyIconCircle.vue'
import SvgIcon from './SvgIcon.vue'

import { EnergyStatusDeviceViewModel } from './../models'

@Component({
  components: {
    EnergyIconCircle,
    EnergyDeviceValueBox,
    SvgIcon,
  },
})
export default class StatusViewDevice extends Vue {
  @Prop() public device: EnergyStatusDeviceViewModel
  @Prop() public rotate: boolean
  @Prop() public resolveIcon: (key: string) => string

  public get gradientBackgroundStyle() {
    const stops = Gradients.stops(this.gradient)
    const background = `-webkit-linear-gradient(bottom, ${stops[1]} 0%, ${stops[0]}  100%)`
    return {
      background,
    }
  }

  public get gradient() {
    return this.device.powerGradient ?? ''
  }

  @Emit('selected')
  public onSelected() {
    return this.device.id
  }

  public get arrow() {
    const value = this.device.powerValue
    if (!value) {
      return ''
    }
    return value > 0 ? 'eco.arrow-down.white': 'eco.arrow-up.white'
  }
}
</script>

<style lang="scss" scoped>
.device-container {
  height: 100%;
  display: flex;
  flex: 1 1 100%;
	.status-view-device {
    height: 100%;
    flex: 1 1 100%;
		display: flex;
    justify-content: center;
		flex-direction: column;
		min-width: 130px;
	}
	.device-header{
		flex-direction: row;
		display:flex;
    justify-content: center;
		&.rotate {
			margin-top: -30px;
		}
	}
	.device-footer {
    height: calc(100% - 120px);
    margin-top: -30px;
		flex-direction: row;
		display:flex;
		flex: 1 1 100%;
    justify-content: center;
		&.rotate {
			margin-top: 0px;
		}
	}
	.device-flow {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 50px;
	}
}
.name-container-top {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  height: calc(100% - 60px);
}
.name-container-bottom {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  height: calc(100% - 60px);
}
.device-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  writing-mode: vertical-rl;
  vertical-align: middle;
}
.value-and-arrow-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  width: 100px;
  margin-left: -25px;
}
.value-container {
  height: 30px;
  width: 100px;
}
.arrow-container {
  height: 20px;
  display: flex;
  width: 100px;
  justify-content: center;
  color: white;
}

@media screen and (max-height: 750px) {
  .device-header {
    min-height: 100px;
  }
	.device-bubble {
    z-index: 2;
		height: 100px;
		min-height: 100px;
		width: 100px;
	}
}

@media screen and (max-height: 600px) {
  .device-header {
    min-height: 80px;
  }
	.device-bubble {
    z-index: 2;
		height: 80px;
		min-height: 80px;
		width: 80px;
	}
}

@media screen and (min-height: 751px) {
  .device-header {
    min-height: 120px;
  }
	.device-bubble {
		z-index: 2;
		height: 120px;
		min-height: 120px;
		width: 120px;
	}
}

</style>
 
import { AuthenticationGetter } from '@ecocoach/domain-store-modules/src/authentication/types'
import EnvironmentService from '@ecocoach/domain-store-modules/src/services/environment.service'
import Vue from 'vue'
import StackRouter from './router'
import store from './store'
import { PushNotificationOpenedPayload } from './store/modules/app/models'
import { AppAction, AppGetter } from './store/modules/app/types'

declare global {
  interface Window {
    cordova?: any,
    plugins: {
      OneSignal: any,
    },
  }
}

const AppId = '6c2cc4b5-4821-462c-9bb4-299c5f7f8a20'

export function initializePushNotifications() {
  if (EnvironmentService.isRunningOnWeb) {
    Vue.$log.debug('pushNotifications:not on device, skipped')
    return
  }

  if (!store.getters[`authentication/${AuthenticationGetter.loggedIn}`]) {
    Vue.$log.debug('pushNotifications:not logged in, skipped')
    return
  }

  Vue.$log.debug('pushNotifications:registering handler')

  document.addEventListener('deviceready', () => {
    // for debugging
    window.plugins.OneSignal.setLogLevel(4, 0) // logLevel: INFO, visualLevel: NONE

    const notificationOpenedCallback = openResult => {
      // called when app is opened because of notification click
      Vue.$log.debug('pushNotifications:notificationOpenedCallback', openResult)
      store.dispatch(`app/${AppAction.handlePushNotificationOpened}`, {
        ...openResult.notification.payload.additionalData,
        navigate: StackRouter.navigate,
      } as PushNotificationOpenedPayload, { root: true })
    }

    window.plugins.OneSignal.setAppId(AppId)

    // when app is running, we handle handle displaying ourselves (ignore push notifications, use signalR)
    window.plugins.OneSignal.setNotificationWillShowInForegroundHandler(notificationReceivedEvent => {
      Vue.$log.debug('pushNotifications:notificationReceivedCallback', notificationReceivedEvent.notification)
      notificationReceivedEvent.complete(null)
    })

    window.plugins.OneSignal.setNotificationOpenedHandler(notificationOpenedCallback)

    // registering with email to be used in filters from backend
    // option: get and pass backend-created hash as second parameter to avoid impersonation
    const userIdentifier = store.getters[`app/${AppGetter.userIdentifier}`]

    Vue.$log.debug('pushNotifications:promptForPushNotificationsWithUserResponse', userIdentifier)
    window.plugins.OneSignal.promptForPushNotificationsWithUserResponse((results) => {
      Vue.$log.debug('pushNotifications:promptForPushNotificationsWithUserResponseCallback', JSON.stringify(results))
    })

    // Setting External User Id with Callback Available in SDK Version 2.11.2+
    Vue.$log.debug('pushNotifications:setExternalUserId', userIdentifier)
    window.plugins.OneSignal.setExternalUserId(userIdentifier, (results) => {
      // The results will contain push and email success statuses
      console.log('pushNotifications:setExternalUserIdCallback', JSON.stringify(results))
    })
  })
}

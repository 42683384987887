import { ChartUiState, ChartUiMutation } from './types'
import { MutationTree } from 'vuex'
import { PeriodType } from '@ecocoach/domain-store-modules/src/chart/models'

export const mutations: MutationTree<ChartUiState> = {
  [ChartUiMutation.setLoading](state, isLoading: boolean) {
    state.isLoading = isLoading
  },
  [ChartUiMutation.setSelectedChartCollectionId](state, chartCollectionId: string) {
    state.selectedChartCollectionId = chartCollectionId
  },
  [ChartUiMutation.setSelectedChartId](state, chartId: string) {
    state.selectedChartId = chartId
  },
  [ChartUiMutation.setSelectedChartPeriod](state, chartPeriod: PeriodType) {
    state.selectedChartPeriod = chartPeriod
  },
  [ChartUiMutation.setSelectedNodeId](state, nodeId: string | null) {
    state.selectedNodeId = nodeId
  },
  [ChartUiMutation.setScaleSelected](state, scaleSelected: boolean) {
    state.scaleSelected = scaleSelected
  },
  [ChartUiMutation.setSelectedChartPeriodStartDateTime](state, chartPeriodStartDateTime: string) {
    state.selectedChartPeriodStartDateTime = chartPeriodStartDateTime
  },
  [ChartUiMutation.toggleSeriesVisibility](state, seriesKey: string) {
    if (state.hiddenSeriesIds.includes(seriesKey)) {
      state.hiddenSeriesIds = state.hiddenSeriesIds.filter(k => k !== seriesKey)
    } else {
      state.hiddenSeriesIds.push(seriesKey)
    }
  },
  [ChartUiMutation.setDateTimeUtcNow](state, dateTime: string) {
    state.dateTimeUtcNow = dateTime
  },
}

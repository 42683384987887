export interface AlarmLogUiState {
  interacted: boolean
}

export enum AlarmLogUiAction {
  openAlarmLogForProject = 'openAlarmLogForProject',
  loadMoreAlarmLogForProject = 'loadMoreAlarmLogForProject',
}

export enum AlarmLogUiMutation {
  setInteracted = 'setInteracted',
}

export enum AlarmLogUiGetter {
  alarms = 'alarms',
  isInteracted = 'isInteracted',
}

<template>
  <v-layout row align-center justify-content, justify-end>
    <div v-if="showNodeSelection" class="icon-picker-dialog-title">
      {{ 'charts.settings.emsNode' | translate }}
    </div>
    <base-single-select v-if="showNodeSelection"
      :options="nodeOptions"
      option-value="id"
      option-label="label"
      :selectedValue="selectedNodeId"
      @selected="onSelectNodeId($event)"
    />
    <checkbox-with-label v-if="showScaleSelection"
      :value="scaleSelected"
      :text="'charts.settings.scaleToAuthorized' | translate"
      @checked="onSelectScale(!$event)"
    />
  </v-layout>      
</template>

<script lang="ts">
import { DropdownOption } from '@ecocoach/shared-components/src'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const ChartUi = namespace('chartUi')

@Component({
  components: {
  },
})
export default class ChartSettingsInline extends Vue {
  @ChartUi.Getter public showNodeSelection:  boolean
  @ChartUi.Getter public nodeOptions:  DropdownOption[]
  @ChartUi.Getter public selectedNodeId: string
  @ChartUi.Getter public showScaleSelection:  boolean
  @ChartUi.Getter public scaleSelected: boolean
  @ChartUi.Action public selectNodeId: (nodeId: string) => Promise<void>
  @ChartUi.Action public selectScale: (scale: boolean) => Promise<void>
  @ChartUi.Getter public selectedChartId: string
  @ChartUi.Action public selectChart: (id: string) => Promise<void>


  public async onSelectNodeId(nodeId: string) {
    if (nodeId != this.selectedNodeId) {
      await this.selectNodeId(nodeId)
      await this.selectChart(this.selectedChartId)
    }
  }

  public async onSelectScale(scale: boolean) {
    if (scale != this.scaleSelected) {
      await this.selectScale(scale)
      await this.selectChart(this.selectedChartId)
    }
  }
}
</script>
<style lang='scss' scoped>

</style>
    

import { DaysEnum, SceneControlCommandModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'

export interface SelectOption {
  value: string
  label: string
}

export enum ScheduleType {
  oneTimePlay = 'oneTimePlay',
  mondayToFriday = 'mondayToFriday',
  daily = 'daily',
  userdefined = 'userdefined',
}

export const mondayToFriday = [
  DaysEnum.monday,
  DaysEnum.tuesday,
  DaysEnum.wednesday,
  DaysEnum.thursday,
  DaysEnum.friday,
]

export const daily = [...mondayToFriday, DaysEnum.saturday, DaysEnum.sunday]

export interface SceneViewModel {
  id: string
  name: string
  icon: string
  gradient: string
  isActivating: boolean
  scheduleValid: boolean
  scheduleActive: boolean
  scheduleText: string
}

export interface SceneDetailsViewModel extends SceneViewModel {
  selectedDeviceIds: string[]
  scheduleId: string
  scheduleEnabled: boolean
  scheduleTriggerDateTime: string
  scheduleType: ScheduleType
  scheduleRecurringDays: DaysEnum[]
  scheduleLocalDate: string
  scheduleLocalTime: string
  controlCommands: SceneControlCommandModel[]
}

<template>
  <app-view>
    <icon-header
      :title="'settings.account.mfa' | translate"
      icon="eco.account.white"
    />
    <list-navigation-item
      :label="'settings.account.mfa.none' | translate"
      :active="!isEmailMfaEnabled"
      @selected="onSetEmailMfaEnabled(false)"
    />
    <list-navigation-item
      :label="'settings.account.mfa.email' | translate"
      :active="isEmailMfaEnabled"
      @selected="onSetEmailMfaEnabled(true)"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'
import StackRouter from '../router'

const App = namespace('app')

@Component({
  components: {
    AppView,
    IconHeader,
    ListNavigationItem,
  },
})
export default class MfaConfiguration extends Vue {
  @App.Action public setEmailMfaEnabled: (enabled: boolean) => Promise<void>
  @App.Getter public isEmailMfaEnabled: boolean | null

  public async onSetEmailMfaEnabled(value: boolean) {
    Vue.$log.info('onSetEmailMfaEnabled', value)
    if (value !== this.isEmailMfaEnabled) {
      await this.setEmailMfaEnabled(value)
    }
    StackRouter.goBack()
  }
}
</script>

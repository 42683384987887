<template>
  <div class="device-control"
    :eco-control-id="control.id"
    :eco-name-resource-id="control.nameResourceId"
    :eco-app-display-level="control.appDisplayLevel"
    :eco-sort-order="control.sortOrder"
    :class="{ spacious: isIpadMini }"
  >
    <control-component-v-2
      :context="contextV2"
      :control="controlViewModelV2"
      :potential-descendent-controls="[]"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import {
  ControlModelV2,
  ExecuteControlCommandInput,
  PendingControlCommand,
} from '../../../../eco-domain-store-modules/src/plcOperation/models'
import EnvironmentService from '../../../../eco-domain-store-modules/src/services/environment.service'
import { ControlCommand, ControlContextV2, ControlViewModelV2 } from '../../../../shared-components/src/components/deviceControls/v2/models'

const Resource = namespace('resource')
const PlcOperation = namespace('plcOperation')

@Component({
  components: {
    ControlComponentV2: () => import('@ecocoach/shared-components/src/components/deviceControls/ControlComponentV2.vue'),
  },
})
export default class DeviceControl extends Vue {
  @Prop() public control: ControlModelV2
  @Resource.Getter public dictionary
  @PlcOperation.Getter public pendingControlCommand: (controlId: string) => PendingControlCommand | undefined
  @PlcOperation.Action public executeControlCommand: (input: ExecuteControlCommandInput) => Promise<void>

  public get controlViewModelV2() {
    const controlV2 = this.control as ControlModelV2
    return {
      id: controlV2.id,
      type: controlV2.type,
      attributes: controlV2.attributes,
      state: controlV2.state || {},
      pendingState: this.pendingControlCommand(this.control.id)?.pendingValue ?? {},
    } as ControlViewModelV2
  }

  public get contextV2(): ControlContextV2 {
    return {
      executeCommand: (controlId: string, command: ControlCommand) => {
        this.executeControlCommand({
          controlId,
          payload: command,
        })
      },
      resolveStringResource: (resourceKey: string): string => {
        return this.dictionary(resourceKey)
      },
    } as ControlContextV2
  }

  public get isIpadMini() {
    return EnvironmentService.isIpadMini
  }
}
</script>
<style lang='scss' scoped>
.device-control {
  margin: 5px 0px;
  &.spacious {
    margin: 15px 0px;
  }
}
</style>

<style lang="css">
.device-control .description-popover.popUpButton {
  display: none;
}
</style>
<template>
  <div class="icon-button-with-badge">
    <v-badge :color="badgeColor" overlap>
      <template v-slot:badge v-if="badge">
        <div>{{ badge }}</div>
      </template>
      <icon-button
        @buttonClicked="onClick"
        :type="icon ? 'base64' : 'materialIcon'"
        :base64Icon="icon"
        :material-icon-name="materialIcon"
        :height="20"
        :width="20"
        :enabled="enabled"
      />
    </v-badge>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class IconButtonWithBadge extends Vue {
  @Prop({ default: '' }) public icon: string
  @Prop({ default: '' }) public materialIcon: string
  @Prop({ default: '' }) public badge: string
  @Prop({ default: '#fa0f36 '}) public badgeColor: string
  @Prop({ default: true }) public enabled: boolean

  @Emit('click')
  public onClick() {
    return
  }
}
</script>

<style lang="scss" scoped>
.icon-button-with-badge {
  .v-badge {
    .icon-button {
      height: 20px;
      width: 20px;
    }
  }
  .icon-button {
    margin: 0px;
  }
}
</style>

<style lang="css">
.icon-button-with-badge .v-badge__badge {
  font-size: 10px;
  height: 15px;
  width: 15px;
}
</style>